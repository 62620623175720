import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostBinding,
    inject,
    input,
    model,
    OnChanges,
    OnDestroy,
    output,
    SimpleChanges,
    Type,
    viewChild,
    ViewRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { keyboardEventSwitch } from '@trade-platform/ui-utils';
import { AixDropdownFilterPipe } from './dropdown-filter';
import {
    DropdownCellrenderer,
    DropdownCellrendererDirective
} from './dropdown-cellrenderer.directive';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { unionBy } from 'lodash-es';
import { AixButtonComponent, BUTTON_TYPE } from '../aix-button/aix-button.component';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';
import { NgClass, NgTemplateOutlet } from '@angular/common';

export interface WrappedOption {
    option: any;
    label: string;
    value: any;
    selected: boolean;
}

@Component({
    selector: 'aix-dropdown',
    templateUrl: './dropdown.html',
    styleUrls: ['./dropdown.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AixDropdownComponent)
        },
        AixDropdownFilterPipe
    ],
    standalone: true,
    imports: [
        NgClass,
        AixDataTestingDirective,
        DropdownCellrendererDirective,
        AixButtonComponent,
        NgTemplateOutlet
    ]
})
export class AixDropdownComponent implements ControlValueAccessor, OnChanges, OnDestroy {
    private ref = inject(ChangeDetectorRef);
    private dropdownFilter = inject(AixDropdownFilterPipe);

    searchField = viewChild<ElementRef<HTMLInputElement>>('searchFieldRef');
    dropDownList = viewChild<ElementRef<HTMLUListElement>>('dropDownListRef');

    @HostBinding('class.disabled') isDisabled = false;
    @HostBinding('class.aix-multiple') isMultiple = false;

    aixDisabled = model(false);
    isRequired = model(false);
    isDirty = model(false);
    isValid = model(false);
    multiple = input(false);
    toggleBehaviour = input(true);
    standalone = input(false);
    omitFiltering = input(false);
    isExternalFiltering = input(false);
    labelField = input<string>();
    valueField = input<string>();
    actionButtonLabel = input<string>();
    canType = input<boolean>(true);
    itemMessage = input<string>(); // itemMessage can be html
    /**
     * A hook to provide a custom label to dropdown options.
     */
    labelStrategy = input<(option: any) => string>();
    /**
     * A hook to calculate dropdown options selected state.
     */
    selectionStrategy = input<(item: any, opt: WrappedOption) => boolean>();
    /**
     * A hook to process the dropdown result before returning it.
     */
    unwrapSelectionStrategy = input<(selection: WrappedOption[]) => any>();
    /**
     * Optional hook to calculate dropdown options disabled state.
     */
    disableStrategy = input<<T>(item: T) => boolean>((item: any) => false);
    /**
     * A hook to provide a custom filter function that determines which dropdown options match with the search term criteria.
     */
    filterFunction = input<(searchTerm: string, option: any) => boolean>();

    options = model<any[]>();
    placeholder = input<string>();
    customButton = input('icon fa-solid fa-caret-down');
    cellRenderer = input<Type<DropdownCellrenderer>>();
    hint = input<string>('');

    /**
     * Declarative version of writeValue().
     * Setter-only.
     */
    value = input<string>();

    private writeValue$ = new EventEmitter<string>();
    private writeValueSub: Subscription;

    filter = output<string>();
    filterFailed = output<boolean>();
    onClickAction = output();
    onFocused = output();
    onBlurred = output();

    _wrappedOptions: WrappedOption[] = [];
    searchFieldValue = '';
    searchFieldValueLabel = '';
    isSearching = false;
    isDropdownOpen = false;
    isMousedownList = false;
    isClickOpenFlag = false;
    isActionButtonSelected = false;
    hasScroll = false;

    _selection: any = [];
    _propagateChanges: (value: any[]) => void = () => ({});
    _propagateTouches: () => void = () => ({});

    linkButtonType = BUTTON_TYPE.linkBold;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        this.writeValueSub = this.writeValue$.pipe(debounceTime(100)).subscribe(value => {
            this._selection = value;
            this._applySelection();
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.aixDisabled) {
            this.isDisabled = this.aixDisabled();
        }

        if (changes.multiple) {
            this.isMultiple = this.multiple();
        }

        if (changes.value) {
            if (this.value() !== undefined) {
                this.writeValue$.emit(this.value());
            }
        }

        if (changes.options) {
            if (this.options() && this.labelField() && this.valueField()) {
                this._createWrappedOptions();
                this._applySelection();
            }
        }
    }

    onClickActionButton() {
        this.clearSelection();
        this.isActionButtonSelected = true;
        this.searchFieldValueLabel = this.getSearchFieldValue();
        this.onClickAction.emit();
    }

    // @override
    _applySelection() {
        this.isMousedownList = false;

        let innerSelection: any[];
        if (this._selection === undefined || this._selection === null) {
            innerSelection = [];
        } else if (!Array.isArray(this._selection)) {
            innerSelection = [this._selection];
        } else {
            innerSelection = this._selection;
        }
        const selectFunct = (this.selectionStrategy() || this._defaultSelectionStrategy).bind(this);
        this._wrappedOptions.forEach(opt => {
            const selection = innerSelection.find(item => selectFunct(item, opt));
            opt.selected = selection !== undefined && selection !== null;
        });

        this.searchFieldValueLabel = this.getSearchFieldValue();

        if (this.ref) {
            this.detectChanges();
        }
    }

    detectChanges() {
        if (!(this.ref as ViewRef).destroyed) {
            this.ref.detectChanges();
        }
    }

    // @override
    closeDropDown() {
        this.isSearching = false;
        this.isDropdownOpen = false;
        this.searchFieldValueLabel = this.getSearchFieldValue();
        this.resetSelectionLabel();
    }

    // @override
    openDropdown() {
        this.isDropdownOpen = true;
        this.scrollToIndex();
    }

    // @override
    toggleOpenDropdown() {
        if (this.isDropdownOpen) {
            this.closeDropDown();
        } else {
            this.isClickOpenFlag = true;
            ((<ElementRef>this.searchField()).nativeElement as HTMLInputElement).focus();
            this.openDropdown();
        }
    }

    onClickOption($event: Event, option: WrappedOption) {
        this.isActionButtonSelected = false;
        this.searchFieldValueLabel = this.getSearchFieldValue();
        $event.stopPropagation();
        if (!(this.disableStrategy() as <T>(item: T) => boolean)(option.option)) {
            this.isDirty.set(true);
            this.toggleOptionSelection(option);
            this._applySelection();
            this.closeDropDown();
            this.detectChanges();
        }
    }

    resetSelectionLabel() {
        this.searchFieldValue = this.getSelectionLabel();
        this.refreshFilter();
    }

    refreshFilter() {
        this.searchFieldValue = '';
        this.detectChanges();
    }

    onFocus($event: Event) {
        setTimeout(() => {
            $event.stopPropagation();
            if (!this.isClickOpenFlag) {
                this.openDropdown();
                this.onFocused.emit();
                this.isClickOpenFlag = false;
            }
            // Detect changes to display the list
            this.detectChanges();

            // Check if it has scroll and detect changes again to apply the class
            this.hasScroll =
                (<ElementRef>this.dropDownList()).nativeElement.scrollHeight >
                (<ElementRef>this.dropDownList()).nativeElement.clientHeight;
            this.detectChanges();
        }, 100);
    }

    onBlur($event: Event) {
        $event.stopPropagation();

        setTimeout(() => {
            this.onBlurred.emit();
        }, 100);

        // Fix for Safari. When you click de scrollbar it focuses out the input text and closes the list
        if (this.isMousedownList) {
            return;
        }

        setTimeout(() => {
            const doCleanSearch = this.searchFieldValue.length;
            this.completeSelection();

            // Clean up the filter after blurring
            if (doCleanSearch) {
                this.filter.emit(this.searchFieldValue);
            }

            try {
                this.detectChanges();
            } catch (err) {
                // discard
            }
            this.onBlurred.emit(); // Doesn't work on first selection without this in addition to above - JMH;
        }, 300);
    }

    onMouseDownList() {
        this.isMousedownList = true;
    }

    onMouseLeaveList() {
        this.isMousedownList = false;
    }

    getSearchFieldValue() {
        if (this.isActionButtonSelected) {
            return <string>this.actionButtonLabel();
        } else if (this.isSearching) {
            return this.searchFieldValue;
        } else {
            return this.getSelectionLabel();
        }
    }

    // Used by the template to obtain the currently selected option
    getSelectionLabel() {
        if (this.multiple()) {
            return '';
        }

        const res = this._wrappedOptions.filter(opt => opt.selected).map(opt => opt.label);
        return res.length > 0 ? res[0] : this._selection ? this._selection.toString() : '';
    }

    getSelection() {
        const res = this._wrappedOptions.filter(opt => opt.selected);
        return res.length > 0 ? res[0] : null;
    }

    completeSelection() {
        if (this.isSearching) {
            const selectedItem = this.getSelection();
            const selectedOptionIndex = this.findIndex(-1);
            if (selectedOptionIndex > -1) {
                const filteredWrappedOptions = this._getFilteredOptions();
                const isOptionInFilter =
                    filteredWrappedOptions.findIndex(opt => opt === selectedItem) > -1;

                const nextOptionIndex = isOptionInFilter
                    ? // Current option selected is inside filter, so we select that option
                      this._wrappedOptions.findIndex(opt => opt.selected)
                    : // Otherwise we toggle the next available in the search
                      this.findNextIndex(selectedOptionIndex);

                if (!this.multiple) {
                    this.toggleOptionSelection(this._wrappedOptions[nextOptionIndex]);
                }
                this._applySelection();
            }
        }

        (<ElementRef>this.searchField()).nativeElement.value = this.isActionButtonSelected
            ? this.actionButtonLabel()
            : this.getSelectionLabel();
        this.isSearching = false;
        this.searchFieldValueLabel = this.getSearchFieldValue();
        if (!this.isClickOpenFlag) {
            this.closeDropDown();
        }
        this.isClickOpenFlag = false;
    }

    private onKeyDownKeyMatcher = keyboardEventSwitch({
        Tab: (event: KeyboardEvent) => {
            if (this.isExternalFiltering()) {
                this.refreshFilter();
                this.omitFiltering() && this.filter.emit(this.searchFieldValue);
            } else if (
                (<ElementRef>this.searchField()).nativeElement.value !== '' &&
                this.isSearching
            ) {
                // We're searching and there's no selection made
                if (!this.getSelection() || this.multiple()) {
                    this.selectNext();
                }
                this.completeSelection();
            }
            this.closeDropDown();
        }
    });

    onKeyDown(event: KeyboardEvent) {
        event.stopPropagation();
        this.isActionButtonSelected = false;
        this.searchFieldValueLabel = this.getSearchFieldValue();
        this.onKeyDownKeyMatcher.withEvent(event);
    }

    onKeyUpDeleteBackspace() {
        this.isSearching = true;
        const prevSearch = this.searchFieldValue;
        this.searchFieldValue = (<ElementRef>this.searchField()).nativeElement.value;
        this.searchFieldValueLabel = this.getSearchFieldValue();

        if ((<ElementRef>this.searchField()).nativeElement.value === '') {
            if (this.multiple()) {
                // Unselect last item selected in the wrapped options if we're backspacing with an empty field
                if (!prevSearch) {
                    const itemRemoved = this._selection[this._selection.length - 1];
                    const wrappedOp = this._wrappedOptions.find(op => op.value === itemRemoved.id);

                    if (wrappedOp) {
                        wrappedOp.selected = false;
                    }

                    this._unwrapSelectedOptions(itemRemoved);
                    this._propagateChanges(this._selection);
                    this._propagateTouches();
                }
            } else {
                this.clearSelection();
            }
        } else {
            this.openDropdown();
        }
        this.detectChanges();
    }

    private onKeyUpKeyMatcher = keyboardEventSwitch({
        Enter: (event: KeyboardEvent) => {
            if ((<ElementRef>this.searchField()).nativeElement.value === '') {
                if (!this.multiple()) {
                    this.clearSelection();
                }
            } else if (this.isSearching) {
                // We're searching and there's no selection made
                if (!this.getSelection() || this.multiple()) {
                    this.selectNext();
                }
                this.completeSelection();
            }
            this.isDirty.set(true);
            this.closeDropDown();
            this.detectChanges();
        },
        Escape: (event: KeyboardEvent) => {
            this.onEscapeKey();
        },
        ArrowDown: (event: KeyboardEvent) => {
            this.isDirty.set(true);
            if (!this.isDropdownOpen) {
                this.openDropdown();
            }
            if (!this.multiple()) {
                this.selectNext();
            }
        },
        ArrowUp: (event: KeyboardEvent) => {
            this.isDirty.set(true);
            if (!this.isDropdownOpen) {
                this.openDropdown();
            }
            if (!this.multiple()) {
                this.selectPrevious();
            }
        },
        Tab: (event: KeyboardEvent) => {
            this.isDirty.set(true);
            this.openDropdown();
        },
        Delete: (event: KeyboardEvent) => {
            this.isDirty.set(true);
            this.onKeyUpDeleteBackspace();
        },
        Backspace: (event: KeyboardEvent) => {
            this.isDirty.set(true);
            this.onKeyUpDeleteBackspace();
        },
        default: (event: KeyboardEvent) => {
            this.isDirty.set(true);
            this.isSearching = true;
            this.searchFieldValue = (<ElementRef>this.searchField()).nativeElement.value;
            this.searchFieldValueLabel = this.getSearchFieldValue();
            this.openDropdown();
            this.detectChanges();
        }
    });

    onKeyUp(event: KeyboardEvent) {
        event.stopPropagation();
        this.onKeyUpKeyMatcher.withEvent(event);

        this.filterFailed.emit(this.isSearching && this._getFilteredOptions().length === 0);

        // Emit filter change;
        if (this.omitFiltering()) {
            this.filter.emit(this.searchFieldValue);
        }
    }

    onEscapeKey() {
        this.resetSelectionLabel();
        this.closeDropDown();
    }

    selectNext() {
        const selectedOptionIndex = this.findIndex();
        const nextOptionIndex = this.findNextIndex(selectedOptionIndex);

        if (selectedOptionIndex > -1 && nextOptionIndex > -1) {
            this.toggleOptionSelection(this._wrappedOptions[nextOptionIndex]);
            this._applySelection();
            this.keyScrollToIndex();
        }
    }

    selectPrevious() {
        const selectedOptionIndex = this.findIndex();
        const nextOptionIndex = this.findPreviousIndex(selectedOptionIndex);

        if (selectedOptionIndex > -1 && nextOptionIndex > -1) {
            this.toggleOptionSelection(this._wrappedOptions[nextOptionIndex]);
            this._applySelection();
            this.keyScrollToIndex();
        }
    }

    scrollToIndex() {
        const selectedOptionIndex = this.findIndex();
        (<ElementRef>this.dropDownList()).nativeElement.scrollTo(0, selectedOptionIndex * 42);
    }

    keyScrollToIndex() {
        const selectedOptionIndex = this.findIndex();
        setTimeout(
            () =>
                (<ElementRef>this.dropDownList()).nativeElement.scrollTo(
                    0,
                    selectedOptionIndex * 42
                ),
            1
        );
    }

    findIndex(defaultIndex = 0) {
        let selectedItemIndex;

        if (this.isSearching) {
            const filteredWrappedOptions = this._getFilteredOptions();
            selectedItemIndex = filteredWrappedOptions.findIndex(opt => opt.selected);
        } else {
            selectedItemIndex = this._wrappedOptions.findIndex(opt => opt.selected);
        }

        if (selectedItemIndex < 0) {
            selectedItemIndex = defaultIndex;
        }

        return selectedItemIndex;
    }

    findNextIndex(selectedOptionIndex: number) {
        let nextIndex = Math.min(selectedOptionIndex + 1, this._wrappedOptions.length - 1);

        if (this.isSearching) {
            const filteredWrappedOptions = this._getFilteredOptions();
            const nextItemIndex = filteredWrappedOptions.findIndex(opt => opt.selected) + 1;
            const nextItem = filteredWrappedOptions[nextItemIndex];
            nextIndex = this._wrappedOptions.findIndex(opt => opt === nextItem);
        }

        return nextIndex;
    }

    findPreviousIndex(selectedOptionIndex: number) {
        let previousIndex = Math.max(selectedOptionIndex - 1, 0);

        if (this.isSearching) {
            const filteredWrappedOptions = this._getFilteredOptions();
            const previousItemIndex = filteredWrappedOptions.findIndex(opt => opt.selected) - 1;
            const previousItem = filteredWrappedOptions[previousItemIndex];
            previousIndex = this._wrappedOptions.findIndex(opt => opt === previousItem);
        }

        return previousIndex;
    }

    registerOnChange(fn: (value: any[]) => void) {
        this._propagateChanges = fn;
    }

    registerOnTouched(fn: () => void) {
        this._propagateTouches = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.aixDisabled.set(isDisabled);
    }

    writeValue(value: any) {
        if (value === this._selection) {
            return;
        }
        this._selection = value;
        this._applySelection();
    }

    _defaultSelectionStrategy(item: any, opt: WrappedOption) {
        return item[<string>this.valueField()] === opt.value;
    }

    _createWrappedOptions() {
        if (!(this.options() instanceof Array)) {
            this.options.set([]);
        }
        this._wrappedOptions = (<any[]>this.options()).map(opt => ({
            option: opt,
            label: this.calculateLabel(opt),
            value: opt[<string>this.valueField()],
            selected: false
        }));
    }

    calculateLabel(opt: any) {
        return this.labelStrategy()
            ? (<(option: any) => string>this.labelStrategy())(opt)
            : opt[<string>this.labelField()];
    }

    _defaultUnwrapSelectionStrategy(selection: WrappedOption[]) {
        return selection.map(opt => opt.option);
    }

    _unwrapSelectedOptions(itemToRemove: any = null) {
        const unwrapSelectFunct = (
            this.unwrapSelectionStrategy() || this._defaultUnwrapSelectionStrategy
        ).bind(this);

        const currentSelection = this._selection;
        const selectedItems = unwrapSelectFunct(this._wrappedOptions.filter(opt => opt.selected));
        if (this.multiple() && currentSelection && selectedItems) {
            if (!itemToRemove) {
                this._selection = unionBy(currentSelection, selectedItems, 'id');
            } else {
                this._selection = this._selection.filter(
                    (item: any) => item.id !== itemToRemove?.id
                );
            }
        } else {
            this._selection = unwrapSelectFunct(this._wrappedOptions.filter(opt => opt.selected));
        }
    }

    _getFilteredOptions() {
        if (this._wrappedOptions.find(x => x.label === 'United States')) {
            const first = 'United States';
            this._wrappedOptions.sort((x, y) => {
                return x.label == first ? -1 : y.label == first ? 1 : 0;
            });
        }

        if (this.filterFunction()) {
            if (this.omitFiltering()) {
                return this._wrappedOptions;
            } else {
                return this._wrappedOptions.filter(opt =>
                    (<(searchTerm: string, option: any) => boolean>this.filterFunction())(
                        this.searchFieldValue,
                        opt.option
                    )
                );
            }
        } else {
            return this.dropdownFilter.transform(
                this._wrappedOptions,
                this.searchFieldValue,
                this.omitFiltering()
            );
        }
    }

    removeChip(option: { [key: string]: string }) {
        this._wrappedOptions.some((item: WrappedOption) => {
            const result = item.value === option[<string>this.valueField()];
            if (result) {
                item.selected = false;
            }

            return result;
        });

        this._unwrapSelectedOptions(option);
        this._propagateChanges(this._selection);
        this._propagateTouches();
    }

    toggleOptionSelection(option: WrappedOption) {
        if (option) {
            if (!this.multiple()) {
                this.clearSelection(false);
            }

            const opt = this._wrappedOptions.find(op => op.value === option.value);
            if (opt) {
                if (this.multiple() && this.toggleBehaviour()) {
                    opt.selected = !opt.selected;
                } else {
                    opt.selected = true;
                }
            }
            this._unwrapSelectedOptions(opt?.selected ? null : opt?.option);
            this._propagateChanges(this._selection);
            this._propagateTouches();
        }
    }

    clearSelection(propagate = true) {
        this._wrappedOptions.forEach(opt => (opt.selected = false));
        if (propagate) {
            this.isDirty.set(true);
            this._unwrapSelectedOptions();
            this._propagateChanges(this._selection);
            this._propagateTouches();
        }
    }

    public validate(c: UntypedFormControl) {}

    ngOnDestroy() {
        this.ref.detach();
        this.writeValueSub.unsubscribe();
    }
}
