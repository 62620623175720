<div
    class="assign-box u-p24"
    #containerRef
    (transitionend)="onTransitionEnds($event)"
    [ngStyle]="styles"
>
    <div class="assign-box-title-container">
        <span (click)="hide()" class="fa-regular fa-xmark assign-box-close-button"></span>
        <h2 class="assign-box-title">{{ headingTitle() }}</h2>
        @if (headingText()) {
        <p>{{ headingText() }}</p>
        }
    </div>

    <form [formGroup]="selectionForm">
        <div class="aix-flex-grid aix-form__container u-mt16 u-mb16">
            <div class="aix-form__group">
                <aix-dropdown
                    #selectionDropdownRef
                    formControlName="selectionDropdown"
                    [isRequired]="true"
                    [isValid]="!isSelectionEmpty()"
                    [labelField]="labelField()"
                    [valueField]="valueField()"
                    placeholder=""
                    [standalone]="true"
                    [options]="availableOptions()"
                    [multiple]="true"
                    [omitFiltering]="true"
                    [disableStrategy]="disableStrategy"
                    [labelStrategy]="labelStrategy()"
                    (filter)="onFilterItems($event)"
                    [customButton]="
                        isLoading()
                            ? 'icon fa-solid fa-spinner fa-spin'
                            : 'icon fa-solid fa-caret-down'
                    "
                >
                    {{ dropdownLabel() }}
                </aix-dropdown>
            </div>
        </div>
    </form>

    <ng-content></ng-content>

    <div class="aix-toolbar">
        <aix-button
            [data-testing]="'cancel-assign-button'"
            (click)="hide()"
            [buttonType]="cancelButtonType"
            [buttonLabel]="'Cancel'"
        >
        </aix-button>
        <aix-button
            [data-testing]="'confirm-assign-button'"
            [isDisabled]="isSelectionEmpty()"
            (click)="stageSelectedItems()"
            [buttonLabel]="assignLabel()"
        >
        </aix-button>
    </div>
</div>
