<div class="u-mt16">
    <p class="u-mt6 u-mb16">Please label the forms you uploaded.</p>

    <form [formGroup]="relationForm">
        <div formArrayName="relations">
            @for (relation of relationsControls;track relation;let i = $index) {
            <div>
                <p class="u-mt16 u-mb8">{{ formFiles[i].file?.name }}</p>

                <div class="aix-flex-grid aix-form__container u-mt16 u-mb16" [formGroupName]="i">
                    <div class="aix-form__group">
                        <aix-input
                            formControlName="fileName"
                            placeholder="Add file name"
                            isRequired="true"
                        >
                            File Name
                        </aix-input>
                    </div>
                    @if (!hideFormDocumentOwner) {
                    <div class="aix-form__group">
                        <aix-dropdown
                            class="standalone-dropdown"
                            formControlName="organization"
                            [isRequired]="true"
                            [isValid]="(relationForm?.value?.relations[i].organization?.length >= 0 ?
                                    !!relationForm?.value?.relations[i].organization?.length :
                                    !!relationForm?.value?.relations[i].organization)"
                            labelField="name"
                            valueField="entity"
                            placeholder="Select Organization"
                            [options]="organizations"
                        >
                            Organization
                        </aix-dropdown>
                    </div>
                    }
                </div>
            </div>
            }
        </div>
    </form>
</div>

<div class="aix-toolbar u-mt24">
    <aix-button
        [buttonType]="cancelButtonType"
        [buttonLabel]="'Cancel'"
        (click)="cancelFileUpload()"
    >
    </aix-button>
    <aix-button
        [buttonLabel]="'Save'"
        [buttonType]="saveButtonType"
        [isDisabled]="isInvalid() || (store.orderUpdateFilesRemoteData$ | async | isInProgress)"
        (click)="clickSaveRelations()"
    >
    </aix-button>
</div>
