import { OrderRoutes } from '@advisor-ui/app-services';
import { InjectionToken } from '@angular/core';
import {
    AixAction,
    AixNotification,
    BaseOrder,
    DocumentViewerInitializedPayload,
    DocumentViewerModel,
    DocumentViewerNotificationPayload,
    DocumentViewerSetActionPayload,
    DocumentViewerSetDocumentPayload,
    DocumentViewerSetFilePayload,
    DocumentViewerSetFilesPayload,
    DocumentViewerSetFormPayload,
    DocumentViewerSetPermissionsPayload,
    DocumentViewerSetTabPayload,
    DocumentViewerShowModalPayload,
    ErrorWrapper,
    FileUploadModel,
    LoadOrderFormPayload,
    LoadOrderPayload,
    LoadOrdersPayload,
    LoadOrderSuffix,
    OrderApprovalForm,
    OrderApprovalResponse,
    OrderApprovePayload,
    OrderCancelEnvelopeRequest,
    OrderDocumentDeclinePayload,
    OrderDocumentRemovePayload,
    OrderDocusignPayload,
    OrderForm,
    OrderFormComment,
    OrderFormCommentCreatePayload,
    OrderFormCommentListPayload,
    OrderFormCommentRequestPayload,
    OrderFormCommentResolvePayload,
    OrderFormCommentsResolvePayload,
    OrderFormCommentUpdatePayload,
    OrderId,
    OrderIdPayload,
    OrderMakeChangesResponse,
    OrderMarkNeedsClarificationRequest,
    OrderMarkNeedsClarificationResponse,
    OrderMarkNigoRequest,
    OrderMarkNigoResponse,
    OrderQueueGroup,
    OrderRecord,
    OrderReducerSuffixPayload,
    OrderResendPayload,
    OrderResendResponse,
    OrderSigningMethodPayload,
    OrderSigningMethodResponse,
    OrderSignPayload,
    OrdersStatusHistory,
    OrderStepsModel,
    OrderType,
    Page,
    SetOrderStepPayload,
    SupplementalFile,
    UpdateOrderFilesPayload,
    UpdateOrderPayload,
    UserByAdvisor
} from '@trade-platform/ui-shared';
import { ApiFormError } from '@trade-platform/ui-utils';
import { RemoteData } from 'ngx-remotedata';
import { Observable } from 'rxjs';
import { OrderCancelReviewState } from './store/order-cancel-review/reducer';
import { OrderReviewCompleteState } from './store/order-review-complete/reducer';
import { OrderStartReviewState } from './store/order-start-review/reducer';

export const ORDERS_STORE_FACADE = new InjectionToken<BaseOrdersStoreFacade>(
    'BaseOrdersStoreFacade'
);

/**
 * Façade for all stores local to the Orders Module
 */
export interface BaseOrdersStoreFacade {
    readonly type: OrderType;

    readonly apiUrl: string;

    readonly routes: OrderRoutes;

    readonly actions: {
        resetStates: {
            dispatch: () => void;
        };
        makeChanges: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderIdPayload) => void;
        };
        signingMethod: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderSigningMethodPayload) => void;
        };
        needsClarification: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderMarkNeedsClarificationRequest) => void;
        };
        resumeApprovals: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderIdPayload) => void;
        };
        markNigo: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderMarkNigoRequest) => void;
        };
        getUsersByAdvisor: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: string) => void;
            reset: () => void;
        };
        completeTraining?: {
            success$: Observable<AixAction>;
        };
        setWarningNotification: {
            success$: Observable<AixAction>;
            dispatch: (payload: AixNotification) => void;
        };
        setErrorNotification: {
            success$: Observable<AixAction>;
            dispatch: (payload: AixNotification) => void;
        };
        getOrder: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: LoadOrderPayload) => void;
        };
        resetOrder: {
            dispatch: (payload: { reducerSuffix: LoadOrderSuffix }) => void;
        };
        updateOrder: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: UpdateOrderPayload) => void;
            reset: () => void;
        };
        getForm: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: LoadOrderFormPayload) => void;
        };
        getFormComments: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderFormCommentListPayload) => void;
        };
        resolveFormComment: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderFormCommentResolvePayload) => void;
        };
        createFormComment: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderFormCommentCreatePayload) => void;
        };
        updateFormComment: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderFormCommentUpdatePayload) => void;
        };
        deleteFormComment: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderFormCommentRequestPayload) => void;
        };
        resolveFormComments: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderFormCommentsResolvePayload) => void;
        };
        cancel: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderIdPayload) => void;
        };
        updateOrderFiles: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: UpdateOrderFilesPayload) => void;
        };
        cancelEnvelope: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderCancelEnvelopeRequest) => void;
        };
        declineDocument: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderDocumentDeclinePayload) => void;
        };
        undeclineDocument: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderDocumentDeclinePayload) => void;
        };
        showFirstAuthPerson: {
            success$: Observable<AixAction>;
        };
        hideFirstAuthPerson: {
            success$: Observable<AixAction>;
        };
        showSecondAuthPerson: {
            success$: Observable<AixAction>;
            dispatch: () => void;
        };
        hideSecondAuthPerson: {
            success$: Observable<AixAction>;
        };
        removeDocument: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderDocumentRemovePayload) => void;
        };
        finalizeOrder: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderIdPayload) => void;
            reset: () => void;
        };
        orderStartReview: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderIdPayload) => void;
        };
        orderCancelReview: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderIdPayload) => void;
        };
        orderReviewComplete: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderIdPayload) => void;
        };
        takeOrderOffline: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderIdPayload) => void;
        };
        mapSignatures: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderIdPayload) => void;
        };
        finishMapSignatures: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderIdPayload) => void;
        };
        sendDocusign: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderDocusignPayload) => void;
            reset: () => void;
        };
        orderFormsComplete: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderIdPayload) => void;
        };
        submit: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderIdPayload) => void;
        };
        approve: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderApprovePayload) => void;
        };
        approveStart: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderIdPayload) => void;
            reset: () => void;
        };
        documentViewerReset: {
            success$: Observable<AixAction>;
            dispatch: () => void;
        };
        documentViewerShowNotification: {
            success$: Observable<AixAction>;
            dispatch: (payload: DocumentViewerNotificationPayload) => void;
        };
        documentViewerHideNotification: {
            success$: Observable<AixAction>;
            dispatch: () => void;
        };
        documentViewerShowModal: {
            success$: Observable<AixAction>;
            dispatch: (payload: DocumentViewerShowModalPayload) => void;
        };
        documentViewerSetAction: {
            success$: Observable<AixAction>;
            dispatch: (payload: DocumentViewerSetActionPayload) => void;
        };
        documentViewerSetDocument: {
            success$: Observable<AixAction>;
            dispatch: (payload: DocumentViewerSetDocumentPayload) => void;
        };
        documentViewerSetFile: {
            success$: Observable<AixAction>;
            dispatch: (payload: DocumentViewerSetFilePayload) => void;
        };
        documentViewerSetFiles: {
            success$: Observable<AixAction>;
            dispatch: (payload: DocumentViewerSetFilesPayload) => void;
        };
        documentViewerSetForm: {
            success$: Observable<AixAction>;
            dispatch: (payload: DocumentViewerSetFormPayload) => void;
        };
        documentViewerSetOffline: {
            success$: Observable<AixAction>;
        };
        documentViewerSetPermissions: {
            success$: Observable<AixAction>;
            dispatch: (payload: DocumentViewerSetPermissionsPayload) => void;
        };
        documentViewerSetInitialized: {
            success$: Observable<AixAction>;
            dispatch: (payload: DocumentViewerInitializedPayload) => void;
        };
        documentViewerSetTab: {
            success$: Observable<AixAction>;
            dispatch: (payload: DocumentViewerSetTabPayload) => void;
        };
        documentViewerReturnTradePackage: {
            success$: Observable<AixAction>;
        };
        resend: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderResendPayload) => void;
        };
        showMakeChangesModal: {
            success$: Observable<AixAction>;
            dispatch: () => void;
        };
        returnToUploader: {
            success$: Observable<AixAction>;
            dispatch: () => void;
        };
        loadOrderStatusHistory: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderIdPayload) => void;
        };
        loadDocumentTypes: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: string) => void;
        };
        loadApprovalForms: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderIdPayload) => void;
        };
        sign: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderIdPayload) => void;
        };
        clearInvestor: {
            success$: Observable<AixAction>;
            dispatch: (payload: OrderReducerSuffixPayload) => void;
        };
        signingComplete: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderIdPayload) => void;
        };
        setOrderStep: {
            success$: Observable<AixAction>;
            dispatch: (payload: SetOrderStepPayload) => void;
        };
        setTrainingMessage: {
            success$: Observable<AixAction>;
            dispatch: (payload: string) => void;
        };
        loadOrderQueues: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: () => void;
        };
        loadOrders: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: LoadOrdersPayload) => void;
        };
        orderTriggerMakeChanges: {
            success$: Observable<AixAction>;
        };
        cancelUpload: {
            success$: Observable<AixAction>;
        };

        // admin actions
        orderNigoToNeedsClarification: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderIdPayload) => void;
        };
        orderToWetsign: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderIdPayload) => void;
        };
        orderMarkComplete: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderIdPayload) => void;
        };
        orderResubmit: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderIdPayload) => void;
        };
        orderPushUpdatedForm: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: { orderId: string; formId: string }) => void;
        };
        orderMoveBackInWorkflow: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderIdPayload) => void;
        };
        orderMoveForwardInWorkflow: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderIdPayload) => void;
        };
        orderAddBlindCarbonCopyToEnvelope: {
            success$: Observable<AixAction>;
            failure$: Observable<AixAction>;
            dispatch: (payload: OrderIdPayload) => void;
        };
    };

    /**
     * Orders
     */
    readonly ordersRemoteData$: Observable<RemoteData<Page<OrderRecord>, ErrorWrapper>>;
    readonly ordersSuccess$: Observable<Page<OrderRecord>>;
    readonly ordersError$: Observable<ErrorWrapper>;
    orders: any;

    /**
     * Order filters
     */
    readonly orderFiltersRemoteData$: Observable<RemoteData<OrderQueueGroup[], ErrorWrapper>>;
    readonly orderFiltersSuccess$: Observable<OrderQueueGroup[]>;
    orderFilters: OrderQueueGroup[];

    /**
     * Order
     */
    readonly orderRemoteData$: Observable<RemoteData<BaseOrder, ErrorWrapper>>;
    readonly orderSuccess$: Observable<BaseOrder>;
    order: BaseOrder;

    /**
     * Order Users by Advisor
     */
    readonly orderUsersByAdvisorRemoteData$: Observable<RemoteData<UserByAdvisor[], ErrorWrapper>>;
    readonly orderUsersByAdvisorSuccess$: Observable<UserByAdvisor[]>;
    readonly orderUsersByAdvisorFailure$: Observable<ErrorWrapper>;
    usersByAdvisor: UserByAdvisor[];

    /**
     * Order Update
     */
    readonly orderUpdateRemoteData$: Observable<RemoteData<OrderId, ErrorWrapper | ApiFormError>>;
    readonly orderUpdateSuccess$: Observable<OrderId>;
    readonly orderUpdateFailure$: Observable<ErrorWrapper | ApiFormError>;

    /**
     * Order Resend
     */
    readonly orderResendRemoteData$: Observable<RemoteData<OrderResendResponse, ErrorWrapper>>;
    readonly orderResendSuccess$: Observable<OrderResendResponse>;
    readonly orderResendFailure$: Observable<ErrorWrapper>;

    /**
     * Order Submit
     */
    readonly orderSubmitRemoteData$: Observable<RemoteData<null, ErrorWrapper>>;
    readonly orderSubmitSuccess$: Observable<null>;
    readonly orderSubmitFailure$: Observable<ErrorWrapper>;

    /**
     * Order Cancel
     */
    readonly cancelEnvelopeRemoteData$: Observable<RemoteData<boolean, ErrorWrapper>>;
    readonly cancelEnvelopeSuccess$: Observable<boolean>;
    readonly cancelEnvelopeFailure$: Observable<ErrorWrapper>;

    /**
     * Cancel Envelope
     */

    readonly orderCancelRemoteData$: Observable<RemoteData<null, ErrorWrapper>>;

    /**
     * Order Finalize
     */
    readonly orderFinalizeRemoteData$: Observable<RemoteData<null, ErrorWrapper>>;
    readonly orderFinalizeSuccess$: Observable<null>;

    /**
     * Order Start Review
     */
    readonly orderStartReviewRemoteData$: Observable<OrderStartReviewState>;
    readonly orderStartReviewSuccess$: Observable<null>;
    readonly orderStartReviewFailure$: Observable<ErrorWrapper>;

    /**
     * Order Cancel Review
     */
    readonly orderCancelReviewRemoteData$: Observable<OrderCancelReviewState>;
    readonly orderCancelReviewSuccess$: Observable<null>;
    readonly orderCancelReviewFailure$: Observable<ErrorWrapper>;

    /**
     * Order Review Complete
     */
    readonly orderReviewCompleteRemoteData$: Observable<OrderReviewCompleteState>;
    readonly orderReviewCompleteSuccess$: Observable<null>;
    readonly orderReviewCompleteFailure$: Observable<ErrorWrapper>;

    /**
     * Order Approval Forms
     */
    readonly orderApprovalFormsRemoteData$: Observable<
        RemoteData<OrderApprovalForm[], ErrorWrapper>
    >;
    readonly orderApprovalFormsSuccess$: Observable<OrderApprovalForm[]>;
    orderApprovalForms: OrderApprovalForm[];

    /**
     * Order Form Comment
     */
    readonly orderFormCommentRemoteData$: Observable<RemoteData<OrderFormComment, ErrorWrapper>>;
    readonly orderFormCommentSuccess$: Observable<OrderFormComment>;
    orderFormComment: OrderFormComment;

    /**
     * Order Form Comment Create
     */
    readonly orderFormCommentCreateRemoteData$: Observable<
        RemoteData<OrderFormComment, ErrorWrapper>
    >;
    readonly orderFormCommentCreateSuccess$: Observable<OrderFormComment>;
    readonly orderFormCommentCreateFailure$: Observable<ErrorWrapper>;

    /**
     * Order Form Comment Delete
     */
    readonly orderFormCommentDeleteRemoteData$: Observable<
        RemoteData<OrderFormComment, ErrorWrapper>
    >;
    readonly orderFormCommentDeleteSuccess$: Observable<OrderFormComment>;
    readonly orderFormCommentDeleteFailure$: Observable<ErrorWrapper>;

    /**
     * Order Form Comment Update
     */
    readonly orderFormCommentUpdateRemoteData$: Observable<
        RemoteData<OrderFormComment, ErrorWrapper>
    >;
    readonly orderFormCommentUpdateSuccess$: Observable<OrderFormComment>;
    readonly orderFormCommentUpdateFailure$: Observable<ErrorWrapper>;

    /**
     * Order Form Comments
     */
    readonly orderFormCommentsRemoteData$: Observable<RemoteData<OrderFormComment[], ErrorWrapper>>;
    readonly orderFormCommentsSuccess$: Observable<OrderFormComment[]>;
    orderFormComments: OrderFormComment[];

    /**
     * Order NIGO
     */
    readonly orderMarkNigoRemoteData$: Observable<RemoteData<OrderMarkNigoResponse, ErrorWrapper>>;
    readonly orderMarkNigoSuccess$: Observable<OrderMarkNigoResponse>;
    orderMarkNigo: OrderMarkNigoResponse;

    /**
     * Order Needs Clarification
     */
    readonly orderMarkNeedsClarificationRemoteData$: Observable<
        RemoteData<OrderMarkNeedsClarificationResponse, ErrorWrapper>
    >;
    readonly orderMarkNeedsClarificationSuccess$: Observable<OrderMarkNeedsClarificationResponse>;
    orderMarkNeedsClarification: OrderMarkNeedsClarificationResponse;

    /**
     * Make Changes / Edit Order
     */
    readonly orderMakeChangesRemoteData$: Observable<
        RemoteData<OrderMakeChangesResponse, ErrorWrapper>
    >;
    readonly orderMakeChangesSuccess$: Observable<OrderMakeChangesResponse>;
    orderMakeChanges: OrderMakeChangesResponse;

    /**
     * Signing Method
     */
    readonly orderSigningMethodRemoteData$: Observable<
        RemoteData<OrderSigningMethodResponse | undefined, ErrorWrapper>
    >;
    readonly orderSigningMethodSuccess$: Observable<OrderSigningMethodResponse | undefined>;
    orderSigningMethod: OrderSigningMethodResponse | undefined;

    /**
     * Update files
     */
    readonly orderUpdateFilesRemoteData$: Observable<
        RemoteData<OrderId, ErrorWrapper | ApiFormError>
    >;
    readonly orderUpdateFilesSuccess$: Observable<OrderId>;
    orderUpdateFiles: OrderId;

    /**
     * Order Document Decline
     */
    readonly orderDocumentDeclineRemoteData$: Observable<RemoteData<null, ErrorWrapper>>;
    readonly orderDocumentDeclineSuccess$: Observable<null>;

    /**
     * Order Document Undecline
     */
    readonly orderDocumentUndeclineRemoteData$: Observable<RemoteData<null, ErrorWrapper>>;
    readonly orderDocumentUndeclineSuccess$: Observable<null>;

    /**
     * Order Document Remove
     */
    readonly orderDocumentRemoveRemoteData$: Observable<RemoteData<null, ErrorWrapper>>;
    readonly orderDocumentRemoveSuccess$: Observable<null>;

    /**
     * Order Sign
     */

    readonly orderSignRemoteData$: Observable<RemoteData<OrderSignPayload, ErrorWrapper>>;
    readonly orderSignSuccess$: Observable<OrderSignPayload>;

    /**
     * Order Signing Complete
     */
    readonly orderSigningCompleteRemoteData$: Observable<RemoteData<null, ErrorWrapper>>;
    readonly orderSigningCompleteSuccess$: Observable<null>;

    /**
     * Document Types
     */
    readonly documentTypesRemoteData$: Observable<
        RemoteData<SupplementalFile[], ErrorWrapper | ApiFormError>
    >;
    readonly documentTypesSuccess$: Observable<SupplementalFile[]>;
    documentTypes: SupplementalFile[];

    /**
     * Order MapSignatures
     */
    readonly orderMapSignaturesRemoteData$: Observable<RemoteData<null, ErrorWrapper>>;
    readonly orderMapSignaturesSuccess$: Observable<null>;
    readonly orderMapSignaturesFailure$: Observable<ErrorWrapper>;

    /**
     * Order Finish MapSignatures
     */
    readonly orderFinishMapSignaturesRemoteData$: Observable<RemoteData<null, ErrorWrapper>>;
    readonly orderFinishMapSignaturesSuccess$: Observable<null>;
    readonly orderFinishMapSignaturesFailure$: Observable<ErrorWrapper>;

    /**
     * Order Docusign Send
     */
    readonly orderDocusignSendRemoteData$: Observable<RemoteData<null, ErrorWrapper>>;
    readonly orderDocusignSendSuccess$: Observable<null>;
    readonly orderDocusignSendFailure$: Observable<ErrorWrapper>;

    /**
     * Order Approve
     */
    readonly orderApproveRemoteData$: Observable<RemoteData<OrderApprovalResponse, ErrorWrapper>>;
    readonly orderApproveSuccess$: Observable<OrderApprovalResponse>;
    readonly orderApproveFailure$: Observable<ErrorWrapper>;

    /**
     * Order Forms Complete
     */
    readonly orderFormsCompleteRemoteData$: Observable<RemoteData<null, ErrorWrapper>>;
    readonly orderFormsCompleteSuccess$: Observable<null>;
    readonly orderFormsCompleteFailure$: Observable<ErrorWrapper>;

    /**
     * Order Take Offline
     */
    readonly orderTakeOfflineRemoteData$: Observable<RemoteData<null, ErrorWrapper>>;
    readonly orderTakeOfflineSuccess$: Observable<null>;
    readonly orderTakeOfflineFailure$: Observable<ErrorWrapper>;

    /**
     * Order Status History
     */
    readonly orderStatusHistoryRemoteData$: Observable<
        RemoteData<OrdersStatusHistory[], ErrorWrapper>
    >;
    readonly orderStatusHistorySuccess$: Observable<OrdersStatusHistory[]>;
    readonly orderStatusHistoryFailure$: Observable<ErrorWrapper>;

    /**
     * Order Form
     */
    readonly orderFormRemoteData$: Observable<RemoteData<OrderForm, ErrorWrapper>>;
    readonly orderFormSuccess$: Observable<OrderForm>;
    readonly orderFormFailure$: Observable<ErrorWrapper>;

    /**
     * DocumentViewer
     */
    readonly documentViewer$: Observable<DocumentViewerModel>;
    documentViewer: DocumentViewerModel;

    /**
     * Order Steps
     */
    readonly orderSteps$: Observable<OrderStepsModel>;
    orderSteps: OrderStepsModel;

    /**
     * File Upload
     */
    readonly fileUpload$: Observable<FileUploadModel>;
    fileUpload: FileUploadModel;

    /**
     * Get Order Using Service For Polling
     */
    getOrder(orderId: string): Observable<BaseOrder>;
}
