<form [formGroup]="form">
    <div class="header--section">
        <div class="-heading">
            <h3>Advisor Information</h3>
        </div>
    </div>

    <div class="aix-flex-grid__col">
        <div class="aix-form__group">
            <aix-dropdown
                #firmDropdownRef
                [aixPendingField]="{ control: form.get('firm') }"
                [formControlName]="'firm'"
                [isRequired]="true"
                [isValid]="(form.value.firm?.length >= 0 ?
                        !!form.value.firm?.length :
                        !!form.value.firm)"
                [options]="firms()"
                [labelStrategy]="firmLabelStrategy"
                labelField="name"
                valueField="id"
                placeholder=""
                [data-testing]="'firm'"
                (ngModelChange)="selectFirm($event[0])"
            >
                Firm Name
            </aix-dropdown>
        </div>
    </div>
    @if (form.value.firm) {
    <div
        [hidden]="(form.value.firm.length === 1 && (form.value.firm[0].type === 'RIA' || form.value.firm[0].type === 'Broker Dealer' || form.value.firm[0].type === 'Insurance')) || !form.value.firm.length"
    >
        <aix-radio-group
            formControlName="firmType"
            [aixPendingField]="{ control: form.get('firmType') }"
            [options]="advisorTypes"
            [title]="'Please note how you will be submitting this business.'"
            [labelField]="'title'"
            [valueField]="'value'"
            [isValid]="!!form.get('firmType')?.valid"
            [isRequired]="true"
        >
        </aix-radio-group>
    </div>
    }

    <div class="aix-flex-grid__col">
        <div class="aix-form__group">
            <aix-dropdown
                #advisorDropdownRef
                [aixPendingField]="{ control: form.get('repCode') }"
                [formControlName]="'repCode'"
                [isRequired]="true"
                [isValid]="(form.value.repCode?.length >= 0 ?
                        !!form.value.repCode?.length :
                        !!form.value.repCode)"
                labelField="listName"
                valueField="id"
                placeholder=""
                [data-testing]="'advisor'"
                [options]="startOrder()?.repCodes"
                (ngModelChange)="selectRepCode($event[0])"
            >
                Choose a Rep ID
            </aix-dropdown>
        </div>
    </div>

    <div class="aix-flex-grid__col">
        <div class="aix-form__group">
            <aix-dropdown
                #solicitingAdvisorRef
                [aixPendingField]="{ control: form.get('advisor') }"
                [formControlName]="'advisor'"
                [isRequired]="true"
                [isValid]="(form.value.advisor?.length >= 0 ?
                        !!form.value.advisor?.length :
                        !!form.value.advisor)"
                labelField="displayName"
                valueField="id"
                placeholder=""
                [data-testing]="'solicitingAdvisor'"
                [options]="advisors()"
                (ngModelChange)="selectAdvisor($event[0])"
            >
                Choose Soliciting Advisor
            </aix-dropdown>
        </div>
    </div>
</form>
