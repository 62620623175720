import { inject, Injectable } from '@angular/core';
import { UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ReducerManager, select, Store } from '@ngrx/store';
import {
    extractStreamValue,
    fromObservable,
    LogService,
    objectHasValue,
    stringIsNotEmpty
} from '@trade-platform/ui-utils';
import {
    clone as _clone,
    get as _get,
    groupBy as _groupBy,
    isArray,
    merge as _merge,
    mergeWith as _mergeWith,
    set as _set,
    unset as _unset
} from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import {
    auditTime,
    buffer,
    debounceTime,
    distinctUntilChanged,
    filter,
    first,
    map,
    share
} from 'rxjs/operators';
import { caseOf, just, Maybe, nothing, withDefault } from 'ts.data.maybe';
import {
    DynamicFormActions,
    DynamicFormActionTypes,
    DynamicFormAddControlAction,
    DynamicFormAssignPatchedValueAction,
    DynamicFormCheckboxGroupRecalculateDisabledOptionsAction,
    DynamicFormCheckboxGroupRecalculateOptionsAction,
    DynamicFormControlSetPropertyAction,
    DynamicFormControlValidateAction,
    DynamicFormDestroyAction,
    DynamicFormDropdownRecalculateOptionsAction,
    DynamicFormRadioGroupRecalculateOptionsAction,
    DynamicFormRecalculateCalcExpressionsAction,
    DynamicFormRecalculateDisableRelationsAction,
    DynamicFormRecalculateRequiredRelationsAction,
    DynamicFormRecalculateSetLabelConditionalsAction,
    DynamicFormRemoveControlAction,
    DynamicFormRemoveControlAndDataAction,
    DynamicFormRepeaterRemoveItemAction,
    DynamicFormSetDataAction,
    DynamicFormSetNameAction,
    DynamicFormSetRepeaterDataAction
} from './dynamic-form-store/actions';
import { DynamicFormCalculatedExpressions } from './dynamic-form-store/calculated-expressions';
import {
    CheckboxGroup2ControlState,
    CheckboxGroupControlState,
    DropdownControlState,
    DynamicFormControlState,
    DynamicFormState,
    RadioGroupControlState,
    RefId,
    RepeaterControlState
} from './dynamic-form-store/model';
import { DynamicFormRelations } from './dynamic-form-store/relations';
import {
    compileRepeaterTemplate,
    createOptionGroupFromHostFieldConfig,
    FormUID,
    FormUIDGenerator,
    getControlDataBinding,
    getControlsByDataPath,
    getDataByPath,
    getDataPathByFieldConfig,
    getFormControlStateByRefId,
    getRepeaterControls,
    getStoreControls,
    getStoreControlsByDataPath,
    isCheckboxGroupFormControlState
} from './dynamic-form-store/utils';
import { calculateDecimalLimitedValue, ObservableMapFuncts } from './dynamic-form.utils';
import toposort from 'toposort';
import { FieldMapType } from './dynamic-form.helper';
import { Logger } from 'typescript-logging';
import {
    AnyOfFieldConfig,
    BaseFieldConfig,
    CalculatedFieldConfig,
    CalculatedFieldOptionsConfig,
    Checkboxgroup2FieldConfig,
    CheckboxgroupFieldConfig,
    ControlFieldConfig,
    DetachedFieldConfig,
    FieldConfig,
    FieldRelation,
    FieldRelationAction,
    IntlPhoneFieldConfig,
    isControlConfig,
    isMaskFormatControl,
    isNotificationConfig,
    LimitedDecimalConfig,
    MaskFormatFieldConfig,
    OneOfFieldConfig,
    optionField,
    OptionFieldConfig,
    RadiogroupFieldConfig,
    RepeaterFieldConfig,
    SelectFieldConfig
} from '@trade-platform/form-fields';
import { isRequiredChecksValidator } from './components/checkboxgroup/required-checks-validator';
import * as Lazy from 'lazy.js';
import { DynamicFormComponent } from '@trade-platform/dynamic-forms';

/**
 * DynamicFormStore
 *
 * Ngrx Store for a DynamicForm
 */
@Injectable()
export class DynamicFormStore {
    private reducerManager = inject(ReducerManager);
    private relationsManager = inject(DynamicFormRelations);
    private calcExpManager = inject(DynamicFormCalculatedExpressions);
    private store = inject<Store<Record<string, DynamicFormState>>>(Store);
    private logService = inject(LogService);
    private formUIDGen = inject(FormUIDGenerator);

    readonly formUID: FormUID = this.formUIDGen.generateFormUID();
    private recalculateDisableRelationsEmitter$ = new Subject<never>();
    private recalculateRequireRelationsEmitter$ = new Subject<never>();
    private recalculateSetLabelConditionalsEmitter$ = new Subject<never>();
    private recalculateCalcExpressionsEmitter$ = new Subject<never>();
    private recalculateCheckboxGroupDisabledOptionsEmitter$ = new Subject<RefId>();
    private recalculateCheckboxGroupOptionsEmitter$ = new Subject<RefId>();
    private recalculateRadioGroupOptionsEmitter$ = new Subject<RefId>();
    private recalculateDropdownOptionsEmitter$ = new Subject<RefId>();
    private validateEmitter$ = new Subject<RefId>();
    private patchedValue: Record<string, any> = {};
    private subscriptions: Subscription[] = [];
    private subscriptionKeys: Record<RefId, Subscription[]> = {};
    static readonly DEBOUNCE = 10;
    static readonly VALIDATION_DELAY_TIME = 20;

    private formInitialized = false;
    private formInitializedInterval: number;
    private hasDirtyRepeater = false;
    isFormInitialized$ = new BehaviorSubject<boolean>(false);

    private visibleControls$: Observable<DynamicFormControlState[]>;

    private observableMapFuncts: ObservableMapFuncts;

    /**
     * Observable that emits the data of all form controls that are not detached or hidden by a relation.
     */
    readonly value$: Observable<Record<string, any>>;

    /**
     * Observable that emits the whole Store data.
     */
    readonly rawValue$: Observable<Record<string, any>>;

    /**
     * Observable that emits the validity of the Dynamic Form controls that are not detached or hidden by a relation.
     */
    readonly isFormValid$: Observable<boolean>;

    /**
     * Observable that emits whether any of the Dynamic Form controls that are not detached or hidden by a relation are dirty or not.
     */
    readonly isFormDirty$: Observable<boolean>;

    /**
     * Observable that emits the percentage (0% to 100%) of form completion
     */
    readonly formProgress$: Observable<number>;

    /**
     * Observable that emits the field maps
     */
    readonly fieldMap$: Observable<{ [refId: string]: FieldMapType }>;

    /**
     * Observable that emits the oneOf field maps
     */
    readonly oneOfMap$: Observable<{ [refId: string]: string }>;

    readonly LOG: Logger;

    formRef: DynamicFormComponent;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        this.relationsManager.setFormUID(this.formUID);
        this.calcExpManager.setFormUID(this.formUID);
        this.LOG = this.logService.getLogger('components.dynamic-form.dynamic-form-store');

        this.reducerManager.addReducer(
            this.formUID.value,
            this.ctrlReducerFactory(this.formUID, {
                formName: '',
                data: {},
                controls: {},
                pathsMarkedForNull: {}
            })
        );

        this.subscriptions.push(
            this.recalculateDisableRelationsEmitter$
                .pipe(debounceTime(DynamicFormStore.DEBOUNCE))
                .subscribe(() => {
                    this.store.dispatch(
                        new DynamicFormRecalculateDisableRelationsAction(this.formUID)
                    );

                    const dropdownControls$ = this.store.pipe(
                        select(state => state[this.formUID.value].controls),
                        map(ctrlDict =>
                            Lazy(Object.keys(ctrlDict))
                                .filter(key => {
                                    const ctrl = ctrlDict[key].fieldConfig as ControlFieldConfig;
                                    const oneOf = (ctrl as unknown as OneOfFieldConfig).oneOf;
                                    const hasRelations = isArray(oneOf)
                                        ? oneOf.some(item => !!item.relations)
                                        : false;
                                    return (
                                        ctrl.type === 'dropdown' &&
                                        !(ctrl as DetachedFieldConfig).detached &&
                                        hasRelations
                                    );
                                })
                                .map(key => ctrlDict[key])
                                .toArray()
                        )
                    );

                    dropdownControls$.pipe(first()).subscribe(dropdowns => {
                        dropdowns.forEach(dropdown =>
                            this.recalculateDropdownOptionsEmitter$.next(
                                dropdown.fieldConfig.refId as string
                            )
                        );
                    });

                    const radioGroupControls$ = this.store.pipe(
                        select(state => state[this.formUID.value].controls),
                        map(ctrlDict =>
                            Lazy(Object.keys(ctrlDict))
                                .filter(key => {
                                    const ctrl = ctrlDict[key].fieldConfig as ControlFieldConfig;
                                    const oneOf = (ctrl as unknown as OneOfFieldConfig).oneOf;
                                    const hasRelations = isArray(oneOf)
                                        ? oneOf.some(item => !!item.relations)
                                        : false;
                                    return (
                                        ctrl.type === 'radioGroup' &&
                                        !(ctrl as DetachedFieldConfig).detached &&
                                        hasRelations
                                    );
                                })
                                .map(key => ctrlDict[key])
                                .toArray()
                        )
                    );

                    radioGroupControls$.pipe(first()).subscribe(radioGroups => {
                        radioGroups.forEach(radioGroup =>
                            this.recalculateRadioGroupOptionsEmitter$.next(
                                radioGroup.fieldConfig.refId as string
                            )
                        );
                    });

                    const checkboxGroupControls$ = this.store.pipe(
                        select(state => state[this.formUID.value].controls),
                        map(ctrlDict =>
                            Lazy(Object.keys(ctrlDict))
                                .filter(key => {
                                    const ctrl = ctrlDict[key].fieldConfig as ControlFieldConfig;
                                    const anyOf = (ctrl as unknown as AnyOfFieldConfig).anyOf;
                                    const hasRelations = isArray(anyOf)
                                        ? anyOf.some(item => !!item.relations)
                                        : false;
                                    return (
                                        (ctrl.type === 'checkboxGroup' ||
                                            ctrl.type === 'checkboxGroup2') &&
                                        !(ctrl as DetachedFieldConfig).detached &&
                                        hasRelations
                                    );
                                })
                                .map(key => ctrlDict[key])
                                .toArray()
                        )
                    );

                    checkboxGroupControls$.pipe(first()).subscribe(checkboxGroups => {
                        checkboxGroups.forEach(checkboxGroup => {
                            this.recalculateCheckboxGroupOptionsEmitter$.next(
                                checkboxGroup.fieldConfig.refId as string
                            );
                            this.recalculateCheckboxGroupDisabledOptionsEmitter$.next(
                                checkboxGroup.fieldConfig.refId as string
                            );
                        });
                    });
                }),
            this.recalculateRequireRelationsEmitter$
                .pipe(debounceTime(DynamicFormStore.DEBOUNCE))
                .subscribe(() => {
                    this.store.dispatch(
                        new DynamicFormRecalculateRequiredRelationsAction(this.formUID)
                    );
                }),
            this.recalculateSetLabelConditionalsEmitter$
                .pipe(debounceTime(DynamicFormStore.DEBOUNCE))
                .subscribe(() => {
                    this.store.dispatch(
                        new DynamicFormRecalculateSetLabelConditionalsAction(this.formUID)
                    );
                }),
            this.recalculateCalcExpressionsEmitter$
                .pipe(debounceTime(DynamicFormStore.DEBOUNCE))
                .subscribe(() => {
                    this.store.dispatch(
                        new DynamicFormRecalculateCalcExpressionsAction(this.formUID)
                    );
                }),
            this.recalculateCheckboxGroupDisabledOptionsEmitter$
                .pipe(
                    buffer(
                        this.recalculateCheckboxGroupDisabledOptionsEmitter$.pipe(
                            debounceTime(DynamicFormStore.DEBOUNCE)
                        )
                    )
                )
                .subscribe(checkboxGroupRefIds => {
                    Array.from(new Set(checkboxGroupRefIds)).forEach(checkboxGroupRefId => {
                        this.store.dispatch(
                            new DynamicFormCheckboxGroupRecalculateDisabledOptionsAction(
                                this.formUID,
                                checkboxGroupRefId
                            )
                        );
                    });
                }),
            this.recalculateCheckboxGroupOptionsEmitter$
                .pipe(
                    buffer(
                        this.recalculateCheckboxGroupOptionsEmitter$.pipe(
                            debounceTime(DynamicFormStore.DEBOUNCE)
                        )
                    )
                )
                .subscribe(checkboxGroupRefIds => {
                    Array.from(new Set(checkboxGroupRefIds)).forEach(checkboxGroupRefId =>
                        this.store.dispatch(
                            new DynamicFormCheckboxGroupRecalculateOptionsAction(
                                this.formUID,
                                checkboxGroupRefId
                            )
                        )
                    );
                }),
            this.recalculateRadioGroupOptionsEmitter$
                .pipe(
                    buffer(
                        this.recalculateRadioGroupOptionsEmitter$.pipe(
                            debounceTime(DynamicFormStore.DEBOUNCE)
                        )
                    )
                )
                .subscribe(radioGroupRefIds => {
                    Array.from(new Set(radioGroupRefIds)).forEach(radioGroupRefId =>
                        this.store.dispatch(
                            new DynamicFormRadioGroupRecalculateOptionsAction(
                                this.formUID,
                                radioGroupRefId
                            )
                        )
                    );
                }),
            this.recalculateDropdownOptionsEmitter$
                .pipe(
                    buffer(
                        this.recalculateDropdownOptionsEmitter$.pipe(
                            debounceTime(DynamicFormStore.DEBOUNCE)
                        )
                    )
                )
                .subscribe(dropdownRefIds => {
                    Array.from(new Set(dropdownRefIds)).forEach(dropdownRefId =>
                        this.store.dispatch(
                            new DynamicFormDropdownRecalculateOptionsAction(
                                this.formUID,
                                dropdownRefId
                            )
                        )
                    );
                }),
            this.validateEmitter$
                .pipe(
                    buffer(
                        this.validateEmitter$.pipe(
                            auditTime(DynamicFormStore.VALIDATION_DELAY_TIME)
                        )
                    )
                )
                .subscribe(refIds => {
                    this.store.dispatch(new DynamicFormControlValidateAction(this.formUID, refIds));
                })
        );

        this.visibleControls$ = this.store.pipe(
            select(state => state[this.formUID.value].controls),
            map(ctrlDict =>
                Lazy(Object.keys(ctrlDict))
                    .filter(key => !(ctrlDict[key].fieldConfig as DetachedFieldConfig).detached)
                    .map(key => ctrlDict[key])
                    .toArray()
            )
        );
        this.value$ = combineLatest([
            this.visibleControls$,
            this.store.pipe(select(state => state[this.formUID.value]))
        ]).pipe(
            map(([visibleControls, state]) => {
                const value = visibleControls.reduce((acc, ctrl) => {
                    const path = getDataPathByFieldConfig(ctrl.fieldConfig);
                    const ctrlDataValue = _get(state.data, path);
                    if (ctrl.fieldConfig.type !== 'repeater') {
                        _set(acc, path, ctrlDataValue);
                    }
                    return acc;
                }, {});
                Object.keys(state.pathsMarkedForNull).forEach(path => {
                    if (state.pathsMarkedForNull[path] === true) {
                        _set(value, path, null);
                    }
                });
                return value;
            })
        );
        this.rawValue$ = this.store.pipe(select(state => state[this.formUID.value])).pipe(
            map(state => {
                const value = { ...state.data };
                Object.keys(state.pathsMarkedForNull).forEach(path => {
                    if (state.pathsMarkedForNull[path] === true) {
                        _set(value, path, null);
                    }
                });
                return value;
            })
        );
        this.isFormValid$ = this.visibleControls$.pipe(
            map(controls => {
                return controls.every(ctrl => {
                    const isValid = ctrl.validation === null;
                    /**
                     * Notifications have this `makeFormInvalid` attribute that, when set and the notification is visible,
                     * it makes the form invalid.
                     */
                    const isBlocker =
                        isNotificationConfig(ctrl.fieldConfig) &&
                        ctrl.fieldConfig.makeFormInvalid === true;
                    return isValid && !isBlocker;
                });
            })
        );
        this.isFormDirty$ = this.visibleControls$.pipe(
            map(controls => controls.some(ctrl => ctrl.isDirty || this.hasDirtyRepeater))
        );
        this.formProgress$ = this.visibleControls$.pipe(
            debounceTime(500),
            map(controls => {
                let progress = 0;
                const totalControls = controls.length;

                if (totalControls === 0) {
                    progress = -1;
                } else {
                    const flatArray = (arr: Array<any>): Array<any> =>
                        arr.reduce((acc, curVal) => {
                            if (curVal.children || curVal.fieldConfig?.children) {
                                return acc
                                    .concat(curVal)
                                    .concat(
                                        flatArray(curVal.children || curVal.fieldConfig.children)
                                    );
                            }
                            return acc.concat(curVal);
                        }, []);

                    const totalControlsWithValidation = controls.filter(
                        ctrl =>
                            ctrl.fieldConfig.type !== 'group' &&
                            ctrl.fieldConfig.type !== 'groupLight' &&
                            ctrl.fieldConfig.type !== 'freeText' &&
                            ctrl.fieldConfig.type !== 'button' &&
                            ctrl.fieldConfig.type !== 'notification' &&
                            ctrl.fieldConfig.type !== 'expandableText' &&
                            ctrl.fieldConfig.type !== 'repeater'
                    );

                    const validControls = totalControlsWithValidation.filter(
                        ctrl => ctrl.validation === null
                    ).length;

                    progress = totalControlsWithValidation.length
                        ? (validControls * 100) / totalControlsWithValidation.length
                        : 100;
                }
                return progress;
            })
        );
        this.fieldMap$ = this.getFieldMap$();
        this.oneOfMap$ = this.getOneOfMap$();
    }

    private getFieldMap$() {
        let fieldMap: { [refId: string]: FieldMapType };
        const refIdToGroupNameAssoc = (
            refId: string,
            path: string,
            maskFormat?: string | null,
            maskType?: string
        ) => {
            fieldMap[refId] =
                maskFormat && maskType
                    ? { path, maskFormat, maskType }
                    : maskType
                    ? { path, maskType }
                    : { path };

            const ctrlStore = extractStreamValue(this.getControlStoreByRefId(refId));
            const hasRequiredValidator = (ctrlStore.fieldConfig.validation as ValidatorFn[]).some(
                val => val === Validators.required || val === Validators.requiredTrue
            );
            fieldMap[refId].required = ctrlStore.requiredByRelation || hasRequiredValidator;

            // Execute SET_MAP relations
            if (ctrlStore.fieldConfig.relations) {
                ctrlStore.fieldConfig.relations
                    .filter(relation => relation.action === 'SET_MAP')
                    .forEach(relation => {
                        const relationResult =
                            this.relationsManager.fieldToRelations_SET_MAP(relation);

                        if (relationResult) {
                            const oneOfField = ctrlStore.fieldConfig as
                                | RadiogroupFieldConfig
                                | SelectFieldConfig;
                            const isOneOptionField =
                                oneOfField.type === 'radioGroup' || oneOfField.type === 'dropdown';

                            if (isOneOptionField) {
                                fieldMap[relation.refId as string] = {
                                    path: (oneOfField as RadiogroupFieldConfig | SelectFieldConfig)
                                        .refIdField
                                        ? `REF_ID_FIELD_VALUE=${oneOfField.refId}`
                                        : path
                                };
                                fieldMap[relation.refId as string].required =
                                    ctrlStore.requiredByRelation || hasRequiredValidator;

                                if (oneOfField.useDisplayValueKey) {
                                    fieldMap[relation.refId as string].useDisplayValueKey =
                                        oneOfField.useDisplayValueKey;
                                    fieldMap[relation.refId as string].labelField =
                                        oneOfField.labelField;
                                    fieldMap[relation.refId as string].displayValueKey =
                                        oneOfField.oneOf;
                                }
                            } else {
                                fieldMap[relation.refId as string] = fieldMap[refId];
                                fieldMap[relation.refId as string].required =
                                    ctrlStore.requiredByRelation || hasRequiredValidator;
                            }
                        }
                    });
            }
        };

        const ctrls$ = this.store.pipe(select(state => state[this.formUID.value].controls));
        // FIXME: use / return the arr.reduce() result instead of imperatively setting fieldMap
        return ctrls$.pipe(
            map(ctrlDict => {
                fieldMap = {};
                return Lazy(Object.keys(ctrlDict))
                    .map(key => ctrlDict[key].fieldConfig)
                    .filter(
                        (config: BaseFieldConfig) =>
                            (isControlConfig(config) || config.type === 'option') &&
                            config.type !== 'repeater'
                    )
                    .map(
                        (
                            config: any // FIXME: improve types -> ControlFieldConfig & { detached: boolean; refIdField: string }
                        ) => {
                            if (!config.detached) {
                                // Radiogroups and Dropdowns with refIdField do mappings by refIdValue
                                if (
                                    (config.type === 'radioGroup' || config.type === 'dropdown') &&
                                    config.refIdField
                                ) {
                                    // We add "REF_ID_VALUE", the order.forms.XXX.fields.refId will be added later by process.ts
                                    // because we can't know which field belongs to which form inside each component
                                    refIdToGroupNameAssoc(config.refId as string, 'REF_ID_VALUE');

                                    const ctrlStore = ctrlDict[config.refId];
                                    const oneOfField = ctrlStore.fieldConfig as SelectFieldConfig;

                                    // Add/remove displayValueKey custom properties to radio groups and dropdowns mappings
                                    if (oneOfField.useDisplayValueKey) {
                                        fieldMap[config.refId].useDisplayValueKey =
                                            oneOfField.useDisplayValueKey;
                                        fieldMap[config.refId].labelField = oneOfField.labelField;
                                        fieldMap[config.refId].displayValueKey = oneOfField.oneOf;
                                    } else {
                                        delete fieldMap[config.refId].useDisplayValueKey;
                                        delete fieldMap[config.refId].labelField;
                                        delete fieldMap[config.refId].displayValueKey;
                                    }

                                    // Add originalPath custom properties to radio groups and dropdowns mappings
                                    fieldMap[config.refId].originalPath = oneOfField.group
                                        ? `${oneOfField.group}.${oneOfField.name}`
                                        : oneOfField.name;
                                } else if (
                                    config.type === 'option' &&
                                    (config as OptionFieldConfig).host === 'checkboxGroup'
                                ) {
                                    fieldMap[config.refId] = {
                                        path:
                                            (config as ControlFieldConfig).group.length > 0
                                                ? (config as ControlFieldConfig).group +
                                                  '.' +
                                                  config.refId
                                                : config.name
                                    };
                                } else if (config.type === 'checkboxGroup') {
                                    fieldMap[config.refId] = {
                                        path:
                                            (config as ControlFieldConfig).group.length > 0
                                                ? (config as ControlFieldConfig).group +
                                                  '.' +
                                                  config.name
                                                : config.name
                                    };

                                    const ctrlStore = extractStreamValue(
                                        this.getControlStoreByRefId(config.refId)
                                    );
                                    const hasRequiredValidator = (
                                        ctrlStore.fieldConfig.validation as ValidatorFn[]
                                    ).some(val => val === Validators.required);
                                    fieldMap[config.refId].required =
                                        ctrlStore.requiredByRelation || hasRequiredValidator;
                                    fieldMap[config.refId].options = config.anyOf.map(
                                        (op: any) => op[config.refIdField]
                                    );
                                    fieldMap[config.refId].limit = config.limit;
                                } else if (config.type === 'checkboxGroup2') {
                                    const ctrlStore = extractStreamValue(
                                        this.getControlStoreByRefId(config.refId)
                                    );
                                    const hasRequiredValidator = (
                                        ctrlStore.fieldConfig.validation as ValidatorFn[]
                                    ).some(val => val === Validators.required);

                                    fieldMap[config.refId] = {
                                        path:
                                            (config as ControlFieldConfig).group.length > 0
                                                ? (config as ControlFieldConfig).group +
                                                  '.' +
                                                  config.name
                                                : config.name,
                                        checkboxOptions: config.anyOf.map((op: any) => {
                                            return {
                                                refId: op[config.refIdField],
                                                path: config.group
                                                    ? `${config.group}.${config.name}.${
                                                          op[config.refIdField]
                                                      }`
                                                    : `${config.name}.${op[config.refIdField]}`,
                                                required:
                                                    ctrlStore.requiredByRelation ||
                                                    hasRequiredValidator
                                            };
                                        })
                                    };

                                    fieldMap[config.refId].required =
                                        ctrlStore.requiredByRelation || hasRequiredValidator;
                                    fieldMap[config.refId].options = config.anyOf.map(
                                        (op: any) => op[config.refIdField]
                                    );
                                    fieldMap[config.refId].limit = config.limit;
                                } else {
                                    const groupAndName = config.group
                                        ? `${config.group}.${config.name}`
                                        : config.name;

                                    if (isMaskFormatControl(config)) {
                                        refIdToGroupNameAssoc(
                                            config.refId as string,
                                            groupAndName,
                                            (config as MaskFormatFieldConfig).maskFormat,
                                            (config as MaskFormatFieldConfig).maskType
                                        );
                                    } else {
                                        refIdToGroupNameAssoc(config.refId as string, groupAndName);
                                    }
                                }

                                if (config.alternativeRefId) {
                                    fieldMap[config.refId].alternativeRefId =
                                        config.alternativeRefId;
                                }
                            }
                        }
                    )
                    .toArray();
            }),
            map(() => fieldMap)
        );
    }

    private getOneOfMap$() {
        let fieldMap: { [refId: string]: string };
        const ctrls$ = combineLatest([
            this.store.pipe(select(state => state[this.formUID.value].controls)),
            this.store.pipe(select(state => state[this.formUID.value].data))
        ]);
        // FIXME: use / return the arr.reduce() result instead of imperatively setting fieldMap
        return ctrls$.pipe(
            map(([ctrlDict]) => {
                fieldMap = {};
                return Lazy(Object.keys(ctrlDict))
                    .map(key => ctrlDict[key].fieldConfig)
                    .map((config: any) => {
                        if (!config.detached) {
                            // Radiogroups and Dropdowns with refIdField do mappings by refIdValue
                            if (config.type === 'radioGroup' || config.type === 'dropdown') {
                                const value = extractStreamValue(
                                    this.getDataStore(getDataPathByFieldConfig(config))
                                );
                                const oneOfConfig = config as
                                    | RadiogroupFieldConfig
                                    | SelectFieldConfig;
                                const oneOfSelected = Lazy(oneOfConfig.oneOf as any[]).find(
                                    item =>
                                        item[config.valueField as string] === value ||
                                        (item.otherValues && item.otherValues.includes(value))
                                );
                                if (oneOfSelected) {
                                    fieldMap[oneOfConfig.refId as string] =
                                        oneOfSelected[config.refIdField as string];
                                }
                            }
                        }
                    })
                    .toArray();
            }),
            map(() => fieldMap)
        );
    }

    // -- -- -- -- -- -- -- --
    //
    //  Public
    //
    // -- -- -- -- -- -- -- --

    setFormName(name: string) {
        this.store.dispatch(new DynamicFormSetNameAction(this.formUID, name));
    }

    setObservableMapFuncts(observableMapFuncts: ObservableMapFuncts) {
        this.observableMapFuncts = observableMapFuncts;
    }

    getControlStoreByRefId<T extends DynamicFormControlState>(refId: string): Observable<T> {
        return this.store.pipe(
            select(state => {
                // this can happen when a form is destroyed
                return state[this.formUID.value] ? state[this.formUID.value].controls : {};
            }),
            map(controls => controls[refId]),
            filter((control): control is T => control !== undefined), // this can happen when a repeater item is removed or the form destroyed
            distinctUntilChanged((a, b) => a.updatedAt === b.updatedAt),
            share()
        );
    }

    /**
     * Returns an Observable with the value present at the specified group + name path.
     */
    getDataStore<T>(path: string): Observable<T> {
        return this.store.pipe(
            select(state => state[this.formUID.value]),
            filter(form => form !== undefined), // this can happen when the form is destroyed
            map(form => form.data),
            map(data => _get(data, path)),
            share()
        );
    }

    patchStoreValue(value: Record<string, any>) {
        // We don't merge Arrays, just use the whole new thing. We can't assume that both arrays are mergeable.
        const overwriteArrays = (objValue: any, srcValue: any) => {
            if (Array.isArray(objValue)) {
                return srcValue;
            }
        };
        this.patchedValue = _mergeWith(this.patchedValue, value, overwriteArrays);
        this.store.dispatch(new DynamicFormAssignPatchedValueAction(this.formUID));
    }

    destroyPreviousForm() {
        this.LOG.debug('destroyPreviousForm(): Destroying form');
        Object.values(this.subscriptionKeys).forEach(ss => ss.forEach(s => s.unsubscribe()));
        this.subscriptionKeys = {};
        this.patchedValue = {};
        this.store.dispatch(new DynamicFormDestroyAction(this.formUID));
    }

    destroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
        Object.values(this.subscriptionKeys).forEach(ss => ss.forEach(s => s.unsubscribe()));
        this.subscriptionKeys = {};
        this.reducerManager.removeReducer(this.formUID.value);
    }

    // -----------------------
    //
    //  Utils
    //
    // -----------------------

    /**
     * The initial value of a control is either in the last patched value or in the store
     * The patched value is a one off thing, that's why we get rid of it staright away
     */
    private getInitialValue(path: string) {
        const patchedValue = _get(this.patchedValue, path);
        const storeData = getDataByPath(this.store, this.formUID, path);
        const patchedData = objectHasValue(patchedValue) ? patchedValue : storeData;
        _unset(this.patchedValue, path);
        return patchedData;
    }

    /**
     * Slightly modified getInitialValue() version for the repeater
     */
    private getInitialRepeaterValue(path: string) {
        const patchedValue = _get(this.patchedValue, path);
        const storeData = getDataByPath(this.store, this.formUID, path);
        const patchedData = _merge(patchedValue || [], storeData);
        _unset(this.patchedValue, path);
        return Array.isArray(patchedData) ? patchedData : undefined;
    }

    /**
     * Late binding subscription management. Adding.
     */
    private unsubscribeByRefId(refId: string) {
        if (this.subscriptionKeys[refId]) {
            this.subscriptionKeys[refId].forEach(s => s.unsubscribe());
            delete this.subscriptionKeys[refId];
        }
    }

    /**
     * Late binding subscription management. Removing.
     */
    private addSubscriptionByRefId(refId: string, sub: Subscription) {
        if (this.subscriptionKeys[refId]) {
            this.subscriptionKeys[refId].push(sub);
        } else {
            this.subscriptionKeys[refId] = [sub];
        }
    }

    // -----------------------
    //
    //  Controls
    //
    // -----------------------

    addControl(fieldConfig: ControlFieldConfig) {
        const { value$ } = getControlDataBinding(
            this.store,
            _clone(fieldConfig) as any,
            this.observableMapFuncts
        );

        /**
         * Create Control
         */

        const initialState: DynamicFormControlState = {
            fieldConfig,
            isDirty: !!fieldConfig.isDirty,
            validation: null,
            disabledByRelation: false,
            requiredByRelation: false,
            configUpdateByRelation: false,
            injectedServerValidationErrors: [],
            updatedAt: Date.now()
        };
        this.store.dispatch(new DynamicFormAddControlAction(this.formUID, initialState));

        /*
         * Set control data
         */

        const path = getDataPathByFieldConfig(fieldConfig);
        const patchedData = this.getInitialValue(path);
        const boundValue = fromObservable(withDefault(value$, null));
        const defaultValue = caseOf(
            {
                Just: data$ => {
                    // late-binding subscription
                    this.addSubscriptionByRefId(
                        fieldConfig.refId as string,
                        data$.pipe(filter(data => data !== undefined)).subscribe(data => {
                            this.store.dispatch(
                                new DynamicFormSetDataAction(this.formUID, path, data)
                            );
                            this.recalculateCalcExpressionsEmitter$.next(null as never);
                            this.recalculateDisableRelationsEmitter$.next(null as never);
                            this.recalculateRequireRelationsEmitter$.next(null as never);
                            this.recalculateSetLabelConditionalsEmitter$.next(null as never);
                        })
                    );
                    return objectHasValue(patchedData)
                        ? patchedData
                        : objectHasValue(boundValue)
                        ? boundValue
                        : '';
                },
                Nothing: () => (objectHasValue(patchedData) ? patchedData : fieldConfig.value)
            },
            value$
        );

        this.store.dispatch(new DynamicFormSetDataAction(this.formUID, path, defaultValue));

        this.recalculateCalcExpressionsEmitter$.next(null as never);
        this.recalculateDisableRelationsEmitter$.next(null as never);
        this.recalculateRequireRelationsEmitter$.next(null as never);
        this.recalculateSetLabelConditionalsEmitter$.next(null as never);
    }

    removeControl(fieldConfig: BaseFieldConfig | ControlFieldConfig) {
        // When the form has been destroyed we don't want to trigger any of these
        if (getFormControlStateByRefId(this.store, this.formUID, fieldConfig.refId as string)) {
            this.unsubscribeByRefId(fieldConfig.refId as string);
            if (isControlConfig(fieldConfig) && fieldConfig.eraseDataOnDestroy === true) {
                this.store.dispatch(
                    new DynamicFormRemoveControlAndDataAction(
                        this.formUID,
                        fieldConfig.refId as string
                    )
                );
            } else {
                this.store.dispatch(
                    new DynamicFormRemoveControlAction(this.formUID, fieldConfig.refId as string)
                );
            }
            this.recalculateCalcExpressionsEmitter$.next(null as never);
            this.recalculateDisableRelationsEmitter$.next(null as never);
            this.recalculateRequireRelationsEmitter$.next(null as never);
            this.recalculateSetLabelConditionalsEmitter$.next(null as never);
        }
    }

    // -----------------------
    //
    //  Non Controls
    //
    // -----------------------

    addNonControl(fieldConfig: BaseFieldConfig) {
        const initialState: DynamicFormControlState = {
            fieldConfig: fieldConfig as any,
            isDirty: false,
            validation: null,
            disabledByRelation: false,
            requiredByRelation: false,
            configUpdateByRelation: false,
            injectedServerValidationErrors: [],
            updatedAt: Date.now()
        };

        this.store.dispatch(new DynamicFormAddControlAction(this.formUID, initialState));
    }

    // -----------------------
    //
    //  Repeater
    //
    // -----------------------

    addRepeater(fieldConfig: RepeaterFieldConfig) {
        const path = getDataPathByFieldConfig(fieldConfig);
        const patchedData: Record<string, any>[] | undefined = this.getInitialRepeaterValue(path);
        const initialValue: Record<string, any>[] =
            Array.isArray(patchedData) && patchedData.length > 0 ? patchedData : fieldConfig.value;

        const initialState: RepeaterControlState = {
            fieldConfig,
            isDirty: false,
            validation: null,
            disabledByRelation: true,
            requiredByRelation: true,
            configUpdateByRelation: true,
            injectedServerValidationErrors: [],
            updatedAt: Date.now(),
            extra: { compiledTemplate: [] }
        };

        this.store.dispatch(new DynamicFormAddControlAction(this.formUID, initialState));
        this.store.dispatch(
            new DynamicFormSetRepeaterDataAction(
                this.formUID,
                fieldConfig.refId as string,
                initialValue
            )
        );
        this.recalculateCalcExpressionsEmitter$.next(null as never);
        this.recalculateDisableRelationsEmitter$.next(null as never);
        this.recalculateRequireRelationsEmitter$.next(null as never);
        this.recalculateSetLabelConditionalsEmitter$.next(null as never);
    }

    private getRepeaterByPath(path: string): RepeaterControlState | null {
        const controls = getStoreControlsByDataPath(this.store, this.formUID, path);
        return controls.length > 0 && controls[0].fieldConfig.type === 'repeater'
            ? (controls[0] as RepeaterControlState)
            : null;
    }

    addEmptyRepeaterItem(repeaterConfig: RepeaterFieldConfig) {
        const repeaterPath = getDataPathByFieldConfig(repeaterConfig);
        const formSnapshot = extractStreamValue(
            this.store.pipe(select(storeState => storeState[this.formUID.value]))
        );

        const repeater = this.getRepeaterByPath(repeaterPath) as RepeaterControlState;
        const repeaterControls = getRepeaterControls(
            repeaterConfig,
            repeater.extra.compiledTemplate,
            getStoreControls(this.store, this.formUID)
        );

        const value = Lazy(repeaterControls)
            .map(ctrl => {
                const path = getDataPathByFieldConfig(ctrl.fieldConfig);
                return {
                    path: path.replace(/.+\.(\d+)\./g, '[$1].'),
                    value: _get(formSnapshot.data, path)
                };
            })
            .reduce((acc, cur) => _set(acc, cur.path, cur.value), []);

        this.store.dispatch(
            new DynamicFormSetRepeaterDataAction(this.formUID, repeaterConfig.refId as string, [
                ...value,
                {}
            ])
        );
    }

    removeRepeaterItem(repeaterConfig: RepeaterFieldConfig, index: number) {
        this.hasDirtyRepeater = true;
        const repeaterPath = getDataPathByFieldConfig(repeaterConfig);
        const formSnapshot = extractStreamValue(
            this.store.pipe(select(storeState => storeState[this.formUID.value]))
        );

        const repeater = this.getRepeaterByPath(repeaterPath) as RepeaterControlState;
        const repeaterControls = getRepeaterControls(
            repeaterConfig,
            repeater.extra.compiledTemplate,
            getStoreControls(this.store, this.formUID)
        );

        const value = Lazy(repeaterControls)
            .map(ctrl => {
                const path = getDataPathByFieldConfig(ctrl.fieldConfig);
                return {
                    path: path.replace(/.+\.(\d+)\./g, '[$1].'),
                    value: _get(formSnapshot.data, path)
                };
            })
            .reduce((acc, cur) => _set(acc, cur.path, cur.value), []);

        const removedElement = value.splice(index, 1);

        this.store.dispatch(
            new DynamicFormRepeaterRemoveItemAction(
                this.formUID,
                repeaterConfig.refId as string,
                index
            )
        );

        return removedElement;
    }

    removeRepeater(fieldConfig: RepeaterFieldConfig) {
        // When the form has been destroyed we don't want to trigger any of these
        if (getFormControlStateByRefId(this.store, this.formUID, fieldConfig.refId as string)) {
            this.store.dispatch(
                new DynamicFormRemoveControlAction(this.formUID, fieldConfig.refId as string)
            );

            this.recalculateCalcExpressionsEmitter$.next(null as never);
            this.recalculateDisableRelationsEmitter$.next(null as never);
            this.recalculateRequireRelationsEmitter$.next(null as never);
            this.recalculateSetLabelConditionalsEmitter$.next(null as never);
        }
    }

    // -----------------------
    //
    //  CheckboxGroup
    //
    // -----------------------

    addCheckboxgroup(chgrpFieldConfig: CheckboxgroupFieldConfig) {
        /**
         * Create Options control/data
         */
        const { anyOf$ } = getControlDataBinding(
            this.store,
            _clone(chgrpFieldConfig) as any,
            this.observableMapFuncts
        );

        /**
         * Patched value format:
         * { "sysAdmin": null, "admin": true, "signer": null, "submitter": true }
         *
         * chgrpFieldConfig.value format:
         * ["admin", "submitter"]
         */
        const controlPatchedData: Record<RefId, boolean | null> = this.getInitialValue(
            getDataPathByFieldConfig(chgrpFieldConfig)
        );

        const selectedRefIds: RefId[] = objectHasValue(controlPatchedData)
            ? Lazy(Object.entries(controlPatchedData))
                  .filter(([, value]) => !!value)
                  .map(([key]) => key)
                  .toArray()
            : chgrpFieldConfig.value
            ? chgrpFieldConfig.value
            : [];

        const createOptionState = (anyOfItem: Record<string, any>): DynamicFormControlState => ({
            fieldConfig: optionField({
                refId: anyOfItem[chgrpFieldConfig.refIdField as string],
                group: createOptionGroupFromHostFieldConfig(chgrpFieldConfig),
                name: anyOfItem[chgrpFieldConfig.refIdField as string],
                value: anyOfItem[chgrpFieldConfig.valueField as string],
                label: anyOfItem[chgrpFieldConfig.labelField as string],
                subLabel: anyOfItem[chgrpFieldConfig.sublabelField as string],
                tooltip: anyOfItem[chgrpFieldConfig.tooltipField as string],
                parentRefId: anyOfItem.parentRefId,
                validation: [],
                host: 'checkboxGroup',
                relations: anyOfItem.relations
            }),
            disabledByRelation: false,
            requiredByRelation: false,
            configUpdateByRelation: false,
            validation: [],
            isDirty: false,
            injectedServerValidationErrors: [],
            updatedAt: Date.now()
        });

        const createOption = (optionState: DynamicFormControlState) => {
            this.store.dispatch(new DynamicFormAddControlAction(this.formUID, optionState));
            const path = `${optionState.fieldConfig.group}.${optionState.fieldConfig.name}`;
            this.store.dispatch(
                new DynamicFormSetDataAction(
                    this.formUID,
                    path,
                    selectedRefIds.some(refId => refId === optionState.fieldConfig.refId)
                )
            );
        };

        let options: any[] = [];

        caseOf(
            {
                Just: (data$: BehaviorSubject<any[]>) => {
                    const boundAnyOfs = fromObservable(data$);
                    if (boundAnyOfs && boundAnyOfs.length > 0) {
                        options = boundAnyOfs.map(anyOfItem => createOptionState(anyOfItem));
                        options.forEach(optionState => {
                            createOption(optionState);
                        });
                    } else {
                        // late-binding subscription
                        this.addSubscriptionByRefId(
                            chgrpFieldConfig.refId as string,
                            data$
                                .pipe(filter(data => data !== undefined && data !== null))
                                .subscribe(data => {
                                    this.removeCheckboxgroupOptions(chgrpFieldConfig);
                                    options = data.map(anyOfItem => createOptionState(anyOfItem));
                                    options.forEach(optionState => {
                                        createOption(optionState);
                                    });
                                    this.store.dispatch(
                                        new DynamicFormControlSetPropertyAction(
                                            this.formUID,
                                            chgrpFieldConfig.refId as string,
                                            'extra',
                                            {
                                                options: options.map(
                                                    option =>
                                                        option.fieldConfig as OptionFieldConfig
                                                ),
                                                unfilteredOptions: options.map(
                                                    option =>
                                                        option.fieldConfig as OptionFieldConfig
                                                )
                                            }
                                        )
                                    );
                                    this.recalculateCheckboxGroupDisabledOptionsEmitter$.next(
                                        chgrpFieldConfig.refId as string
                                    );
                                    this.validateEmitter$.next(chgrpFieldConfig.refId as string);
                                })
                        );
                    }
                },
                Nothing: () => {
                    options = ((chgrpFieldConfig.anyOf as any[]) || []).map(anyOfItem =>
                        createOptionState(anyOfItem)
                    );
                    options.forEach(optionState => {
                        createOption(optionState);
                    });
                }
            },
            anyOf$
        );

        /**
         * Create Checkboxgroup control
         */
        const initialState: CheckboxGroupControlState = {
            fieldConfig: chgrpFieldConfig,
            isDirty: !!chgrpFieldConfig.isDirty,
            validation: null,
            disabledByRelation: false,
            requiredByRelation: false,
            configUpdateByRelation: false,
            injectedServerValidationErrors: [],
            extra: {
                options: options.map(option => option.fieldConfig as OptionFieldConfig),
                unfilteredOptions: options.map(option => option.fieldConfig as OptionFieldConfig)
            },
            updatedAt: Date.now()
        };
        this.store.dispatch(new DynamicFormAddControlAction(this.formUID, initialState));

        this.recalculateCheckboxGroupOptionsEmitter$.next(chgrpFieldConfig.refId as string);
        this.recalculateCheckboxGroupDisabledOptionsEmitter$.next(chgrpFieldConfig.refId as string);
        this.validateEmitter$.next(chgrpFieldConfig.refId as string);
    }

    // -----------------------
    //
    //  CheckboxGroup2
    //
    // -----------------------

    addCheckboxgroup2(chgrpFieldConfig: Checkboxgroup2FieldConfig) {
        /**
         * Create Options control/data
         */
        const { anyOf$ } = getControlDataBinding(
            this.store,
            _clone(chgrpFieldConfig) as any,
            this.observableMapFuncts
        );

        /**
         * Patched value format:
         * { "sysAdmin": null, "admin": true, "signer": null, "submitter": true }
         *
         * chgrpFieldConfig.value format:
         * ["admin", "submitter"]
         */
        const controlPatchedData: Record<RefId, boolean | null> = this.getInitialValue(
            getDataPathByFieldConfig(chgrpFieldConfig)
        );

        const selectedRefIds: any = objectHasValue(controlPatchedData)
            ? controlPatchedData
            : chgrpFieldConfig.value
            ? chgrpFieldConfig.value
            : {};

        const createOptionState = (anyOfItem: Record<string, any>): DynamicFormControlState => ({
            fieldConfig: optionField({
                refId: anyOfItem[chgrpFieldConfig.refIdField as string],
                group: createOptionGroupFromHostFieldConfig(chgrpFieldConfig),
                name: anyOfItem[chgrpFieldConfig.refIdField as string],
                value: anyOfItem[chgrpFieldConfig.valueField as string],
                label: anyOfItem[chgrpFieldConfig.labelField as string],
                subLabel: anyOfItem[chgrpFieldConfig.sublabelField as string],
                tooltip: anyOfItem[chgrpFieldConfig.tooltipField as string],
                parentRefId: anyOfItem.parentRefId,
                validation: [],
                host: 'checkboxGroup2',
                relations: anyOfItem.relations
            }),
            disabledByRelation: false,
            requiredByRelation: false,
            configUpdateByRelation: false,
            validation: [],
            isDirty: false,
            injectedServerValidationErrors: [],
            updatedAt: Date.now()
        });

        const createOption = (optionState: DynamicFormControlState) => {
            const path = `${optionState.fieldConfig.group}.${optionState.fieldConfig.name}`;
            const value = selectedRefIds.source
                ? selectedRefIds.source[optionState.fieldConfig.refId as string]
                : selectedRefIds[optionState.fieldConfig.refId as string];
            this.store.dispatch(new DynamicFormSetDataAction(this.formUID, path, !!value));
        };

        let options: any[] = [];

        caseOf(
            {
                Just: (data$: BehaviorSubject<any[]>) => {
                    const boundAnyOfs = fromObservable(data$);
                    if (boundAnyOfs && boundAnyOfs.length > 0) {
                        options = boundAnyOfs.map(anyOfItem => createOptionState(anyOfItem));
                        options.forEach(optionState => {
                            createOption(optionState);
                        });
                    } else {
                        // late-binding subscription
                        this.addSubscriptionByRefId(
                            chgrpFieldConfig.refId as string,
                            data$
                                .pipe(filter(data => data !== undefined && data !== null))
                                .subscribe(data => {
                                    options = data.map(anyOfItem => createOptionState(anyOfItem));
                                    options.forEach(optionState => {
                                        createOption(optionState);
                                    });
                                    this.store.dispatch(
                                        new DynamicFormControlSetPropertyAction(
                                            this.formUID,
                                            chgrpFieldConfig.refId as string,
                                            'extra',
                                            {
                                                options: options.map(
                                                    option =>
                                                        option.fieldConfig as OptionFieldConfig
                                                ),
                                                unfilteredOptions: options.map(
                                                    option =>
                                                        option.fieldConfig as OptionFieldConfig
                                                )
                                            }
                                        )
                                    );
                                    this.recalculateCheckboxGroupDisabledOptionsEmitter$.next(
                                        chgrpFieldConfig.refId as string
                                    );
                                    this.validateEmitter$.next(chgrpFieldConfig.refId as string);
                                })
                        );
                    }
                },
                Nothing: () => {
                    options = ((chgrpFieldConfig.anyOf as any[]) || []).map(anyOfItem =>
                        createOptionState(anyOfItem)
                    );
                    options.forEach(optionState => {
                        createOption(optionState);
                    });
                }
            },
            anyOf$
        );

        /**
         * Create Checkboxgroup control
         */
        const initialState: CheckboxGroup2ControlState = {
            fieldConfig: chgrpFieldConfig,
            isDirty: !!chgrpFieldConfig.isDirty,
            validation: null,
            disabledByRelation: false,
            requiredByRelation: false,
            configUpdateByRelation: false,
            injectedServerValidationErrors: [],
            extra: {
                options: options.map(option => option.fieldConfig as OptionFieldConfig),
                unfilteredOptions: options.map(option => option.fieldConfig as OptionFieldConfig)
            },
            updatedAt: Date.now()
        };
        this.store.dispatch(new DynamicFormAddControlAction(this.formUID, initialState));

        this.recalculateCheckboxGroupOptionsEmitter$.next(chgrpFieldConfig.refId as string);
        this.recalculateCheckboxGroupDisabledOptionsEmitter$.next(chgrpFieldConfig.refId as string);
        this.validateEmitter$.next(chgrpFieldConfig.refId as string);
    }

    private removeCheckboxgroupOptions(chgrpFieldConfig: CheckboxgroupFieldConfig) {
        const chgrpState = getFormControlStateByRefId(
            this.store,
            this.formUID,
            chgrpFieldConfig.refId as string
        ) as CheckboxGroupControlState;
        if (chgrpState) {
            chgrpState.extra.options.forEach(opt => {
                this.store.dispatch(
                    new DynamicFormRemoveControlAction(this.formUID, opt.refId as string)
                );
            });
        }
    }

    removeCheckboxgroup(chgrpFieldConfig: CheckboxgroupFieldConfig) {
        // When the form has been destroyed we don't want to trigger any of these
        if (
            getFormControlStateByRefId(this.store, this.formUID, chgrpFieldConfig.refId as string)
        ) {
            this.unsubscribeByRefId(chgrpFieldConfig.refId as string);
            this.removeCheckboxgroupOptions(chgrpFieldConfig);
            this.store.dispatch(
                new DynamicFormRemoveControlAction(this.formUID, chgrpFieldConfig.refId as string)
            );
            this.recalculateCalcExpressionsEmitter$.next(null as never);
            this.recalculateDisableRelationsEmitter$.next(null as never);
            this.recalculateRequireRelationsEmitter$.next(null as never);
            this.recalculateSetLabelConditionalsEmitter$.next(null as never);
        }
    }

    removeCheckboxgroup2(chgrpFieldConfig: Checkboxgroup2FieldConfig) {
        // When the form has been destroyed we don't want to trigger any of these
        if (
            getFormControlStateByRefId(this.store, this.formUID, chgrpFieldConfig.refId as string)
        ) {
            this.unsubscribeByRefId(chgrpFieldConfig.refId as string);
            this.store.dispatch(
                new DynamicFormRemoveControlAction(this.formUID, chgrpFieldConfig.refId as string)
            );
            this.recalculateCalcExpressionsEmitter$.next(null as never);
            this.recalculateDisableRelationsEmitter$.next(null as never);
            this.recalculateRequireRelationsEmitter$.next(null as never);
            this.recalculateSetLabelConditionalsEmitter$.next(null as never);
        }
    }

    // -----------------------
    //
    //  RadioGroup
    //
    // -----------------------

    addRadioGroup(fieldConfig: RadiogroupFieldConfig) {
        const { value$, oneOf$ } = getControlDataBinding(
            this.store,
            _clone(fieldConfig) as any,
            this.observableMapFuncts
        );

        /**
         * Create Options and control
         */
        const createOption = (oneOfItem: Record<string, any>): OptionFieldConfig =>
            optionField({
                refId: oneOfItem[fieldConfig.refIdField as string],
                group: createOptionGroupFromHostFieldConfig(fieldConfig),
                name: oneOfItem[fieldConfig.refIdField as string],
                value: oneOfItem[fieldConfig.valueField as string],
                label: oneOfItem[fieldConfig.labelField as string],
                subLabel: oneOfItem[fieldConfig.sublabelField as string],
                tooltip: oneOfItem[fieldConfig.tooltipField as string],
                otherValues: oneOfItem.otherValues,
                isTitle: oneOfItem[fieldConfig.typeField as string] === 'title',
                disabled: oneOfItem['disabled'],
                validation: [],
                host: 'radioGroup',
                relations: oneOfItem.relations
            });

        let options: OptionFieldConfig[] = [];

        caseOf(
            {
                Just: (data$: BehaviorSubject<any[]>) => {
                    const boundOneOfs: any[] = fromObservable(data$);
                    if (boundOneOfs && boundOneOfs.length > 0) {
                        options = boundOneOfs.map(oneOfItem => createOption(oneOfItem));
                    } else {
                        // late-binding subscription
                        this.addSubscriptionByRefId(
                            fieldConfig.refId as string,
                            data$
                                .pipe(filter(data => data !== undefined && data !== null))
                                .subscribe(data =>
                                    this.store.dispatch(
                                        new DynamicFormControlSetPropertyAction(
                                            this.formUID,
                                            fieldConfig.refId as string,
                                            'extra',
                                            {
                                                options: data.map(oneOfItem =>
                                                    createOption(oneOfItem)
                                                ),
                                                unfilteredOptions: data.map(oneOfItem =>
                                                    createOption(oneOfItem)
                                                )
                                            }
                                        )
                                    )
                                )
                        );
                    }
                },
                Nothing: () => {
                    options = ((fieldConfig.oneOf as any[]) || []).map(oneOfItem =>
                        createOption(oneOfItem)
                    );
                }
            },
            oneOf$
        );

        const initialState: RadioGroupControlState = {
            fieldConfig: fieldConfig,
            isDirty: !!fieldConfig.isDirty,
            validation: null,
            disabledByRelation: false,
            requiredByRelation: false,
            configUpdateByRelation: false,
            injectedServerValidationErrors: [],
            extra: {
                options: options,
                unfilteredOptions: options
            },
            updatedAt: Date.now()
        };
        this.store.dispatch(new DynamicFormAddControlAction(this.formUID, initialState));

        /*
         * Set control data
         */

        const calculateSelectedValue = (
            opts: OptionFieldConfig[],
            value: string
        ): Maybe<string | undefined> => {
            const optionFromValue = Lazy(opts).find(opt => opt.value === value);
            if (optionFromValue) {
                return just(value);
            }

            const optionFromOtherValue = Lazy(opts).find(
                opt => objectHasValue(opt.otherValues) && opt.otherValues.includes(value)
            );
            if (optionFromOtherValue) {
                return just(value);
            }

            const optionFromWildcard = Lazy(opts).find(
                opt => objectHasValue(opt.otherValues) && opt.otherValues.includes('*')
            );
            if (optionFromWildcard) {
                return just(optionFromWildcard.value);
            }

            return nothing();
        };

        const path = getDataPathByFieldConfig(fieldConfig);
        const patchedData = this.getInitialValue(path);
        const boundValue = fromObservable(withDefault(value$, null));
        let defaultValue: string | null | undefined = caseOf(
            {
                Just: data$ => {
                    // late-binding subscription
                    this.addSubscriptionByRefId(
                        fieldConfig.refId as string,
                        data$
                            .pipe(filter(data => data !== undefined && data !== null))
                            .subscribe(data => {
                                const calculatedValue = calculateSelectedValue(options, data);
                                this.store.dispatch(
                                    new DynamicFormSetDataAction(
                                        this.formUID,
                                        path,
                                        withDefault(calculatedValue, null)
                                    )
                                );
                                this.store.dispatch(
                                    new DynamicFormRadioGroupRecalculateOptionsAction(
                                        this.formUID,
                                        fieldConfig.refId as string
                                    )
                                );
                                this.recalculateDisableRelationsEmitter$.next(null as never);
                                this.recalculateRequireRelationsEmitter$.next(null as never);
                                this.recalculateSetLabelConditionalsEmitter$.next(null as never);
                            })
                    );
                    return objectHasValue(patchedData)
                        ? patchedData
                        : objectHasValue(boundValue)
                        ? boundValue
                        : '';
                },
                Nothing: () => (objectHasValue(patchedData) ? patchedData : fieldConfig.value)
            },
            value$
        );

        const calculatedDefaultValue = calculateSelectedValue(options, defaultValue as string);
        defaultValue = withDefault(calculatedDefaultValue, null);

        this.store.dispatch(
            new DynamicFormSetDataAction(
                this.formUID,
                getDataPathByFieldConfig(fieldConfig),
                defaultValue
            )
        );

        this.store.dispatch(
            new DynamicFormRadioGroupRecalculateOptionsAction(
                this.formUID,
                fieldConfig.refId as string
            )
        );

        this.recalculateDisableRelationsEmitter$.next(null as never);
        this.recalculateRequireRelationsEmitter$.next(null as never);
        this.recalculateSetLabelConditionalsEmitter$.next(null as never);
    }

    removeRadioGroup(fieldConfig: RadiogroupFieldConfig) {
        // When the form has been destroyed we don't want to trigger any of these
        if (getFormControlStateByRefId(this.store, this.formUID, fieldConfig.refId as string)) {
            this.unsubscribeByRefId(fieldConfig.refId as string);
            this.store.dispatch(
                new DynamicFormRemoveControlAction(this.formUID, fieldConfig.refId as string)
            );
            this.recalculateCalcExpressionsEmitter$.next(null as never);
            this.recalculateDisableRelationsEmitter$.next(null as never);
            this.recalculateRequireRelationsEmitter$.next(null as never);
            this.recalculateSetLabelConditionalsEmitter$.next(null as never);
        }
    }

    // -----------------------
    //
    //  Dropdown
    //
    // -----------------------

    addDropdown(fieldConfig: SelectFieldConfig) {
        const { value$, oneOf$ } = getControlDataBinding(
            this.store,
            _clone(fieldConfig) as any,
            this.observableMapFuncts
        );

        /**
         * Create Options and control
         */

        const addDropdown = (oneOfs: Record<string, string>[]) => {
            const initialState: DropdownControlState = {
                fieldConfig: fieldConfig,
                isDirty: !!fieldConfig.isDirty,
                validation: null,
                disabledByRelation: false,
                requiredByRelation: false,
                configUpdateByRelation: false,
                injectedServerValidationErrors: [],
                extra: {
                    options: oneOfs,
                    unfilteredOptions: oneOfs
                },
                updatedAt: Date.now()
            };
            this.store.dispatch(new DynamicFormAddControlAction(this.formUID, initialState));
        };

        caseOf(
            {
                Just: data$ => {
                    const boundOneOfs: any[] = fromObservable(data$);
                    if (boundOneOfs && boundOneOfs.length > 0) {
                        addDropdown(boundOneOfs);
                    } else {
                        addDropdown([]);
                    }
                    // late-binding subscription
                    this.addSubscriptionByRefId(
                        fieldConfig.refId as string,
                        data$
                            .pipe(filter(data => data !== undefined && data !== null))
                            .subscribe(data => {
                                this.store.dispatch(
                                    new DynamicFormControlSetPropertyAction(
                                        this.formUID,
                                        fieldConfig.refId as string,
                                        'extra',
                                        {
                                            options: data.map((oneOfItem: any) => oneOfItem),
                                            unfilteredOptions: data.map(
                                                (oneOfItem: any) => oneOfItem
                                            )
                                        }
                                    )
                                );
                            })
                    );
                },
                Nothing: () => addDropdown((fieldConfig.oneOf as any[]) || [])
            },
            oneOf$
        );

        /*
         * Set control data
         */

        const path = getDataPathByFieldConfig(fieldConfig);
        const patchedData = this.getInitialValue(path);
        const boundValue = fromObservable(withDefault(value$, null));
        const defaultValue = caseOf(
            {
                Just: data$ => {
                    // late-binding subscription
                    this.addSubscriptionByRefId(
                        fieldConfig.refId as string,
                        data$
                            .pipe(filter(data => data !== undefined && data !== null))
                            .subscribe(data => {
                                this.store.dispatch(
                                    new DynamicFormSetDataAction(this.formUID, path, data)
                                );
                                this.store.dispatch(
                                    new DynamicFormDropdownRecalculateOptionsAction(
                                        this.formUID,
                                        fieldConfig.refId as string
                                    )
                                );
                                this.recalculateDisableRelationsEmitter$.next(null as never);
                                this.recalculateRequireRelationsEmitter$.next(null as never);
                                this.recalculateSetLabelConditionalsEmitter$.next(null as never);
                            })
                    );
                    return objectHasValue(patchedData)
                        ? patchedData
                        : objectHasValue(boundValue)
                        ? boundValue
                        : '';
                },
                Nothing: () => (objectHasValue(patchedData) ? patchedData : fieldConfig.value)
            },
            value$
        );

        this.store.dispatch(
            new DynamicFormSetDataAction(
                this.formUID,
                getDataPathByFieldConfig(fieldConfig),
                defaultValue
            )
        );

        this.store.dispatch(
            new DynamicFormDropdownRecalculateOptionsAction(
                this.formUID,
                fieldConfig.refId as string
            )
        );

        this.recalculateDisableRelationsEmitter$.next(null as never);
        this.recalculateRequireRelationsEmitter$.next(null as never);
        this.recalculateSetLabelConditionalsEmitter$.next(null as never);
    }

    private formInitialize() {
        // check if the form is initialized
        if (!this.formInitialized) {
            if (this.formInitializedInterval) {
                window.clearInterval(this.formInitializedInterval);
                this.isFormInitialized$.next(false);
            }
            this.formInitializedInterval = window.setInterval(() => {
                this.hasDirtyRepeater = false;
                this.formInitialized = true;
                this.isFormInitialized$.next(true);
                window.clearInterval(this.formInitializedInterval);
            }, 30);
        }
    }

    // -- -- -- -- -- -- -- --
    //
    //  Private
    //  This function update the state of the form
    //
    // -- -- -- -- -- -- -- --

    // FIXME: (joanllenas) avoid mutation.
    private ctrlReducerFactory(formUID: FormUID, initialState: DynamicFormState) {
        const formControl = new UntypedFormControl(); // for validation

        const reducer = (state: DynamicFormState, action: DynamicFormActions): DynamicFormState => {
            this.formInitialize();

            switch (action.type) {
                case DynamicFormActionTypes.SET_NAME: {
                    return {
                        ...state,
                        formName: action.name
                    };
                }
                case DynamicFormActionTypes.ADD_CONTROL: {
                    if (state.controls[action.initialState.fieldConfig.refId as string]) {
                        console.warn(`${action.initialState.fieldConfig.refId} already exists`);
                    }

                    return {
                        ...state,
                        controls: {
                            ...state.controls,
                            [action.initialState.fieldConfig.refId as string]: action.initialState
                        },
                        pathsMarkedForNull: action.initialState.fieldConfig.nullValueOnDestroy
                            ? {
                                  ...state.pathsMarkedForNull,
                                  [getDataPathByFieldConfig(action.initialState.fieldConfig)]: false
                              }
                            : state.pathsMarkedForNull
                    };
                }
                case DynamicFormActionTypes.REMOVE_CONTROL: {
                    // TODO: remove duplicated part with the `DynamicFormActionTypes.REMOVE_CONTROL_AND_DATA` case. Both are almost identical
                    const removingControl = state.controls[action.refId as string];
                    return {
                        ...state,
                        controls: Lazy(Object.keys(state.controls))
                            .filter(refId => refId !== action.refId)
                            .reduce((acc, key) => {
                                acc[key] = state.controls[key];
                                return acc;
                            }, {} as typeof state.controls),
                        pathsMarkedForNull: removingControl.fieldConfig.nullValueOnDestroy
                            ? {
                                  ...state.pathsMarkedForNull,
                                  [getDataPathByFieldConfig(removingControl.fieldConfig)]: true
                              }
                            : state.pathsMarkedForNull
                    };
                }
                case DynamicFormActionTypes.REMOVE_CONTROL_AND_DATA: {
                    // TODO: remove duplicated part with the `DynamicFormActionTypes.REMOVE_CONTROL` case. Both are almost identical
                    const removingControl = state.controls[action.refId as string];
                    const dataPath = getDataPathByFieldConfig(removingControl.fieldConfig);
                    return {
                        ...state,
                        controls: Lazy(Object.keys(state.controls))
                            .filter(refId => refId !== action.refId)
                            .reduce((acc, key) => {
                                acc[key] = state.controls[key];
                                return acc;
                            }, {} as typeof state.controls),
                        pathsMarkedForNull: removingControl.fieldConfig.nullValueOnDestroy
                            ? {
                                  ...state.pathsMarkedForNull,
                                  [dataPath]: true
                              }
                            : state.pathsMarkedForNull,
                        data: _set(state.data, dataPath, undefined)
                    };
                }
                case DynamicFormActionTypes.DESTROY: {
                    // Remove form controls and data (by mutation) silently (without emitting)
                    _set(state, 'data', {});
                    _set(state, 'controls', {});
                    _set(state, 'pathsMarkedForNull', {});

                    this.LOG.debug('form destroyed');

                    // restore formInitialized
                    this.formInitialized = false;
                    this.isFormInitialized$.next(false);

                    return state;
                }
                case DynamicFormActionTypes.SET_DATA: {
                    _set(state.data, action.path, action.value);

                    this.recalculateCalcExpressionsEmitter$.next(null as never);
                    this.recalculateDisableRelationsEmitter$.next(null as never);
                    this.recalculateRequireRelationsEmitter$.next(null as never);
                    this.recalculateSetLabelConditionalsEmitter$.next(null as never);

                    // there might be more than one control assigned to this path
                    const affectedControls = getControlsByDataPath(state.controls, action.path);

                    return {
                        ...state,
                        data: { ...state.data },
                        controls: {
                            ...state.controls,
                            ...affectedControls.reduce((acc, cur) => {
                                this.validateEmitter$.next(cur.fieldConfig.refId as string);
                                acc[cur.fieldConfig.refId as string] = {
                                    ...cur,
                                    // Mark control for change detection
                                    updatedAt: Date.now()
                                };
                                return acc;
                            }, {} as typeof state.controls)
                        }
                    };
                }
                case DynamicFormActionTypes.SET_REPEATER_DATA: {
                    const repeater = state.controls[action.repeaterRefId] as RepeaterControlState;
                    const path = getDataPathByFieldConfig(repeater.fieldConfig);

                    // set compiledTemplate by mutation
                    repeater.extra = {
                        compiledTemplate: compileRepeaterTemplate(
                            repeater.fieldConfig as RepeaterFieldConfig,
                            action.value,
                            false
                        )
                    };

                    // set data by mutation
                    const repeaterData = _set({}, path, action.value);
                    _merge(state.data, repeaterData);

                    // trigger calculations
                    this.recalculateCalcExpressionsEmitter$.next(null as never);
                    this.recalculateDisableRelationsEmitter$.next(null as never);
                    this.recalculateRequireRelationsEmitter$.next(null as never);
                    this.recalculateSetLabelConditionalsEmitter$.next(null as never);

                    // there might be more than one control assigned to this path
                    const affectedControls = getControlsByDataPath(state.controls, path);

                    return {
                        ...state,
                        data: { ...state.data },
                        controls: {
                            ...state.controls,
                            ...affectedControls.reduce((acc, cur) => {
                                this.validateEmitter$.next(cur.fieldConfig.refId as string);
                                acc[cur.fieldConfig.refId as string] = {
                                    ...cur,
                                    // Mark control for change detection
                                    updatedAt: Date.now()
                                };
                                return acc;
                            }, {} as typeof state.controls)
                        }
                    };
                }
                case DynamicFormActionTypes.REMOVE_REPEATER_ITEM: {
                    const repeater = state.controls[action.repeaterRefId] as RepeaterControlState;

                    // Remove all repeater controls (by mutation)
                    const repeaterControlRefIds = getRepeaterControls(
                        repeater.fieldConfig as RepeaterFieldConfig,
                        repeater.extra.compiledTemplate,
                        state.controls
                    )
                        .filter(
                            // The checkboxGroup removes the items internally
                            ctrl => ctrl.fieldConfig.type !== 'checkboxGroup'
                        )
                        .map(ctrl => ctrl.fieldConfig.refId as string);
                    repeaterControlRefIds.forEach(refId => _unset(state.controls, refId));

                    // Remove repeater index data (by mutation)
                    const path = getDataPathByFieldConfig(repeater.fieldConfig);
                    const repeaterData: any[] = _get(state.data, path);
                    const checkboxGroupsControls = getRepeaterControls(
                        repeater.fieldConfig as RepeaterFieldConfig,
                        repeater.extra.compiledTemplate,
                        state.controls
                    ).filter(ctrl => ctrl.fieldConfig.type === 'checkboxGroup');

                    // Consolidate checkbox group data
                    if (checkboxGroupsControls && checkboxGroupsControls.length) {
                        // You're entering Aokigahara
                        const cbgs = _groupBy(
                            checkboxGroupsControls,
                            item => item.fieldConfig.name
                        );

                        const consolidateData = (name: string, cbg: any) => {
                            const cbgProp = cbg[action.index].fieldConfig.name;
                            const copiedProps: any[] = [];
                            // Copy props
                            repeaterData.forEach((item, index) => {
                                copiedProps.push(_clone(repeaterData[index][cbgProp]));
                            });

                            // Copy data to props
                            repeaterData.forEach((item, index) => {
                                if (index > action.index) {
                                    const oldProps = Object.keys(repeaterData[index][cbgProp]);
                                    Object.assign(
                                        repeaterData[index][cbgProp],
                                        copiedProps[index - 1]
                                    );
                                    Object.keys(copiedProps[index - 1]).forEach((key, kIndex) => {
                                        repeaterData[index][cbgProp][key] =
                                            copiedProps[index][oldProps[kIndex]];

                                        // Remove old props
                                        delete repeaterData[index][cbgProp][oldProps[kIndex]];
                                    });
                                }
                            });
                        };

                        Object.keys(cbgs).forEach(key => consolidateData(key, cbgs[key]));
                        // You're leaving Aokigahara
                    }

                    const newRepeaterData = repeaterData.filter(
                        (_, index) => index !== action.index
                    );
                    _set(state.data, path, newRepeaterData);

                    // trigger calculations
                    this.recalculateCalcExpressionsEmitter$.next(null as never);
                    this.recalculateDisableRelationsEmitter$.next(null as never);
                    this.recalculateRequireRelationsEmitter$.next(null as never);
                    this.recalculateSetLabelConditionalsEmitter$.next(null as never);

                    return {
                        ...state,
                        data: { ...state.data },
                        controls: {
                            ...state.controls,
                            // set new compiledTemplate and mark for detection change
                            [repeater.fieldConfig.refId as string]: {
                                ...repeater,
                                // set new compiledTemplate
                                extra: {
                                    compiledTemplate: compileRepeaterTemplate(
                                        repeater.fieldConfig as RepeaterFieldConfig,
                                        newRepeaterData,
                                        false
                                    )
                                },
                                // Mark control for change detection
                                updatedAt: Date.now()
                            } as RepeaterControlState
                        }
                    };
                }
                case DynamicFormActionTypes.CHECKBOX_GROUP_CHANGED: {
                    const checkboxGroupCtrl = state.controls[
                        action.checkboxGroupRefId
                    ] as CheckboxGroupControlState;

                    // Changed Option
                    const optionCtrl = state.controls[action.optionRefId];
                    const optionDataPath = getDataPathByFieldConfig(optionCtrl.fieldConfig);
                    _set(state.data, optionDataPath, action.newValue);

                    // onCheck.uncheckAll support
                    if (
                        objectHasValue(checkboxGroupCtrl.fieldConfig.onCheck) &&
                        checkboxGroupCtrl.fieldConfig.onCheck.uncheckAll === true &&
                        checkboxGroupCtrl.fieldConfig.onCheck.refId === optionCtrl.fieldConfig.refId
                    ) {
                        checkboxGroupCtrl.extra.options.forEach((opt: OptionFieldConfig) => {
                            if (opt.refId !== action.optionRefId) {
                                _set(state.data, getDataPathByFieldConfig(opt), false);
                            }
                        });
                    } else {
                        if (
                            objectHasValue(checkboxGroupCtrl.fieldConfig.onCheck) &&
                            objectHasValue(checkboxGroupCtrl.fieldConfig.onCheck.exclusive) &&
                            checkboxGroupCtrl.fieldConfig.onCheck.exclusive === true &&
                            checkboxGroupCtrl.fieldConfig.onCheck.refId !==
                                optionCtrl.fieldConfig.refId
                        ) {
                            checkboxGroupCtrl.extra.options.forEach((opt: OptionFieldConfig) => {
                                if (opt.refId === checkboxGroupCtrl.fieldConfig?.onCheck?.refId) {
                                    _set(state.data, getDataPathByFieldConfig(opt), false);
                                }
                            });
                        }
                        // Un-check sub options, if parent option is un-checked (only if one column layout)
                        const columnLayout = (
                            checkboxGroupCtrl.fieldConfig as CheckboxgroupFieldConfig
                        ).columnLayout;
                        if (columnLayout === 'one') {
                            checkboxGroupCtrl.extra.options.forEach((opt: OptionFieldConfig) => {
                                if (opt.parentRefId === action.optionRefId) {
                                    _set(state.data, getDataPathByFieldConfig(opt), false);
                                }
                            });
                        }
                    }

                    // Recalculate stuff
                    this.recalculateCheckboxGroupDisabledOptionsEmitter$.next(
                        action.checkboxGroupRefId
                    );
                    this.validateEmitter$.next(checkboxGroupCtrl.fieldConfig.refId as string);

                    return {
                        ...state,
                        data: { ...state.data },
                        controls: {
                            ...state.controls,
                            [action.checkboxGroupRefId]: {
                                ...checkboxGroupCtrl,
                                // Mark control for change detection
                                updatedAt: Date.now()
                            }
                        }
                    };
                }
                case DynamicFormActionTypes.RECALCULATE_CHECKBOX_GROUP_DISABLED_OPTIONS: {
                    const checkboxGroupCtrl = state.controls[
                        action.checkboxGroupRefId
                    ] as CheckboxGroupControlState;

                    if (!checkboxGroupCtrl) {
                        return state;
                    }

                    const disableAllOptionIsChecked = (refId: string) => {
                        const optionDataPath = `${getDataPathByFieldConfig(
                            checkboxGroupCtrl.fieldConfig
                        )}.${refId}`;
                        const value = _get(state.data, optionDataPath);
                        return value;
                    };

                    if (
                        checkboxGroupCtrl.fieldConfig?.onCheck?.disableAll === true &&
                        disableAllOptionIsChecked(checkboxGroupCtrl.fieldConfig.onCheck.refId)
                    ) {
                        checkboxGroupCtrl.extra.options = checkboxGroupCtrl.extra.options.map(
                            opt => {
                                return {
                                    ...opt,
                                    disabled:
                                        opt.refId !== checkboxGroupCtrl.fieldConfig.onCheck?.refId
                                };
                            }
                        );
                    } else {
                        checkboxGroupCtrl.extra.options = checkboxGroupCtrl.extra.options.map(
                            opt => {
                                const newOpt = {
                                    ...opt,
                                    disabled: false
                                };
                                if (objectHasValue(newOpt.parentRefId)) {
                                    if (checkboxGroupCtrl.fieldConfig.type === 'checkboxGroup') {
                                        const parentCheckboxCtrl =
                                            state.controls[newOpt.parentRefId as string];
                                        const parentCheckboxDataPath = getDataPathByFieldConfig(
                                            parentCheckboxCtrl.fieldConfig
                                        );
                                        const parentCheckboxData = _get(
                                            state.data,
                                            parentCheckboxDataPath
                                        );
                                        return {
                                            ...newOpt,
                                            disabled: !parentCheckboxData
                                        };
                                    } else if (
                                        checkboxGroupCtrl.fieldConfig.type === 'checkboxGroup2'
                                    ) {
                                        const parentCheckboxDataPath = getDataPathByFieldConfig(
                                            checkboxGroupCtrl.fieldConfig
                                        );
                                        const parentCheckboxData = _get(
                                            state.data,
                                            parentCheckboxDataPath
                                        );
                                        const parentCheckboxValue =
                                            parentCheckboxData[newOpt.parentRefId as string];
                                        return {
                                            ...newOpt,
                                            disabled: !parentCheckboxValue
                                        };
                                    }
                                }
                                return newOpt;
                            }
                        );
                    }

                    return {
                        ...state,
                        controls: {
                            ...state.controls,
                            [action.checkboxGroupRefId]: {
                                ...checkboxGroupCtrl,
                                // Mark control for change detection
                                updatedAt: Date.now()
                            }
                        }
                    };
                }
                case DynamicFormActionTypes.RECALCULATE_CHECKBOX_GROUP_OPTIONS: {
                    const checkboxGroupCtrl = state.controls[
                        action.checkboxGroupRefId
                    ] as CheckboxGroupControlState;

                    if (!checkboxGroupCtrl) {
                        return state;
                    }

                    let configUpdateByRelation = false;
                    let updatedAt = checkboxGroupCtrl.updatedAt;

                    const result = {
                        ...state,
                        controls: {
                            ...state.controls,
                            [action.checkboxGroupRefId]: {
                                ...checkboxGroupCtrl,
                                // Mark control for change detection
                                configUpdateByRelation,
                                updatedAt
                            }
                        }
                    };

                    checkboxGroupCtrl.extra.options = checkboxGroupCtrl.extra.unfilteredOptions
                        .map((opt, index) => {
                            return {
                                ...opt,
                                disabled: !!checkboxGroupCtrl.extra.options[index]?.disabled
                            };
                        })
                        .filter(opt => {
                            if (opt.relations) {
                                let result = true;
                                opt.relations
                                    .filter(r => r.action !== 'SET_LABEL')
                                    .forEach((rel: FieldRelation) => {
                                        result = this.relationsManager.fieldToRelations(
                                            opt as unknown as FieldConfig,
                                            rel.action
                                        ) as boolean;
                                    });
                                return result;
                            } else {
                                return true;
                            }
                        });

                    const hasConditionalRelation = checkboxGroupCtrl.extra.options.some(item =>
                        item.relations?.some(relation => relation.action === 'SET_LABEL')
                    );

                    // There's a conditional relation
                    if (hasConditionalRelation) {
                        configUpdateByRelation = true;
                        updatedAt = Date.now();

                        const newOptions: OptionFieldConfig[] = [];
                        checkboxGroupCtrl.extra.options.forEach(opt => {
                            const newOpt: any = {
                                ...opt
                            };
                            newOpt.relations
                                ?.filter((r: FieldRelation) => r.action === 'SET_LABEL')
                                .forEach((relation: FieldRelation) => {
                                    const optionRelation =
                                        this.relationsManager.fieldToConditionals(
                                            newOpt as unknown as FieldConfig,
                                            relation.action
                                        );

                                    if (optionRelation.conditionResult) {
                                        const labelField =
                                            checkboxGroupCtrl.fieldConfig.labelField || 'label';
                                        newOpt[labelField] = optionRelation.newValue;
                                    }
                                });

                            newOptions.push(newOpt);
                        });

                        (result.controls[action.checkboxGroupRefId] as CheckboxGroupControlState) =
                            {
                                ...(result.controls[
                                    action.checkboxGroupRefId
                                ] as CheckboxGroupControlState),
                                extra: {
                                    unfilteredOptions: (
                                        result.controls[
                                            action.checkboxGroupRefId
                                        ] as CheckboxGroupControlState
                                    ).extra.unfilteredOptions,
                                    options: newOptions
                                },
                                configUpdateByRelation,
                                updatedAt
                            };
                    }

                    return result;
                }
                case DynamicFormActionTypes.RECALCULATE_RADIO_GROUP_OPTIONS: {
                    const radioGroupCtrl = state.controls[
                        action.radioGroupRefId
                    ] as RadioGroupControlState;

                    if (!radioGroupCtrl) {
                        return state;
                    }

                    radioGroupCtrl.extra.options = radioGroupCtrl.extra.unfilteredOptions.filter(
                        opt => {
                            if (opt.relations) {
                                let result = true;
                                opt.relations
                                    .filter(r => r.action !== 'SET_LABEL')
                                    .forEach((rel: FieldRelation) => {
                                        result = this.relationsManager.fieldToRelations(
                                            opt as unknown as FieldConfig,
                                            rel.action
                                        ) as boolean;
                                    });
                                return result;
                            } else {
                                return true;
                            }
                        }
                    );

                    const hasConditionalRelation = radioGroupCtrl.extra.options.some(item =>
                        item.relations?.some(relation => relation.action === 'SET_LABEL')
                    );

                    // There's a conditional relation
                    if (hasConditionalRelation) {
                        const newOptions: OptionFieldConfig[] = [];
                        radioGroupCtrl.extra.options.forEach(opt => {
                            const newOpt: any = {
                                ...opt
                            };
                            newOpt.relations
                                ?.filter((r: FieldRelation) => r.action === 'SET_LABEL')
                                .forEach((relation: FieldRelation) => {
                                    const optionRelation =
                                        this.relationsManager.fieldToConditionals(
                                            newOpt as unknown as FieldConfig,
                                            relation.action
                                        );

                                    if (optionRelation.conditionResult) {
                                        const labelField =
                                            radioGroupCtrl.fieldConfig.labelField || 'label';
                                        newOpt[labelField] = optionRelation.newValue;
                                    }
                                });

                            newOptions.push(newOpt);
                        });

                        radioGroupCtrl.extra.options = newOptions;
                    }

                    return {
                        ...state,
                        controls: {
                            ...state.controls,
                            [action.radioGroupRefId]: {
                                ...radioGroupCtrl,
                                // Mark control for change detection
                                updatedAt: Date.now()
                            }
                        }
                    };
                }
                case DynamicFormActionTypes.RECALCULATE_DROPDOWN_OPTIONS: {
                    const dropdownCtrl = state.controls[
                        action.dropdownRefId
                    ] as RadioGroupControlState;

                    if (!dropdownCtrl) {
                        return state;
                    }

                    dropdownCtrl.extra.options = dropdownCtrl.extra.unfilteredOptions.filter(
                        opt => {
                            if (opt.relations) {
                                let result = true;
                                opt.relations
                                    .filter(r => r.action !== 'SET_LABEL')
                                    .forEach((rel: FieldRelation) => {
                                        result = this.relationsManager.fieldToRelations(
                                            opt as unknown as FieldConfig,
                                            rel.action
                                        ) as boolean;
                                    });
                                return result;
                            } else {
                                return true;
                            }
                        }
                    );

                    const hasConditionalRelation = dropdownCtrl.extra.options.some(item =>
                        item.relations?.some(relation => relation.action === 'SET_LABEL')
                    );

                    // There's a conditional relation
                    if (hasConditionalRelation) {
                        const newOptions: OptionFieldConfig[] = [];
                        dropdownCtrl.extra.options.forEach(opt => {
                            const newOpt: any = {
                                ...opt
                            };
                            newOpt.relations
                                ?.filter((r: FieldRelation) => r.action === 'SET_LABEL')
                                .forEach((relation: FieldRelation) => {
                                    const optionRelation =
                                        this.relationsManager.fieldToConditionals(
                                            newOpt as unknown as FieldConfig,
                                            relation.action
                                        );

                                    if (optionRelation.conditionResult) {
                                        const labelField =
                                            dropdownCtrl.fieldConfig.labelField || 'label';
                                        newOpt[labelField] = optionRelation.newValue;
                                    }
                                });

                            newOptions.push(newOpt);
                        });

                        dropdownCtrl.extra.options = newOptions;
                    }

                    return {
                        ...state,
                        controls: {
                            ...state.controls,
                            [action.dropdownRefId]: {
                                ...dropdownCtrl,
                                // Mark control for change detection
                                updatedAt: Date.now()
                            }
                        }
                    };
                }
                case DynamicFormActionTypes.RECALCULATE_DISABLE_RELATIONS: {
                    return {
                        ...state,
                        controls: Lazy(Object.entries(state.controls))
                            .map(([key, controlState]) => {
                                const disabledByRelation = this.relationsManager.fieldToRelations(
                                    controlState.fieldConfig as FieldConfig,
                                    'DISABLE'
                                ) as boolean;
                                const updatedAt =
                                    disabledByRelation === controlState.disabledByRelation
                                        ? controlState.updatedAt
                                        : // Mark control for change detection
                                          Date.now();
                                return {
                                    key,
                                    controlState: {
                                        ...controlState,
                                        disabledByRelation,
                                        updatedAt
                                    }
                                };
                            })
                            .reduce((acc, cur) => {
                                acc[cur.key] = cur.controlState;
                                return acc;
                            }, {} as Record<string, DynamicFormControlState>)
                    };
                }
                case DynamicFormActionTypes.SET_DIRTY: {
                    return {
                        ...state,
                        controls: {
                            ...state.controls,
                            [action.refId]: {
                                ...state.controls[action.refId],
                                isDirty: true,
                                // Mark control for change detection
                                updatedAt: Date.now()
                            }
                        }
                    };
                }
                case DynamicFormActionTypes.RECALCULATE_REQUIRE_RELATIONS: {
                    return {
                        ...state,
                        controls: Lazy(Object.entries(state.controls))
                            .map(([key, controlState]) => {
                                const requiredByRelation = this.relationsManager.fieldToRelations(
                                    controlState.fieldConfig as FieldConfig,
                                    'REQUIRE'
                                ) as boolean;
                                let updatedAt = controlState.updatedAt;

                                if (requiredByRelation !== controlState.requiredByRelation) {
                                    this.validateEmitter$.next(
                                        controlState.fieldConfig.refId as string
                                    );
                                    // Mark control for change detection
                                    updatedAt = Date.now();
                                }
                                return {
                                    key,
                                    controlState: {
                                        ...controlState,
                                        requiredByRelation,
                                        updatedAt
                                    }
                                };
                            })
                            .reduce((acc, cur) => {
                                acc[cur.key] = cur.controlState;
                                return acc;
                            }, {} as typeof state.controls)
                    };
                }

                case DynamicFormActionTypes.RECALCULATE_CONDITIONALS: {
                    return {
                        ...state,
                        controls: Lazy(Object.entries(state.controls))
                            .map(([key, controlState]) => {
                                let configUpdateByRelation = false;
                                let updatedAt = controlState.updatedAt;

                                const conditionalActions: {
                                    prop: string;
                                    action: FieldRelationAction;
                                }[] = [];

                                const inputProperties: {
                                    prop: string;
                                    action: FieldRelationAction;
                                }[] = [
                                    { prop: 'label', action: 'SET_LABEL' },
                                    { prop: 'placeholder', action: 'SET_PLACEHOLDER' }
                                ];

                                inputProperties.forEach(item => {
                                    if (controlState.fieldConfig.hasOwnProperty(item.prop)) {
                                        conditionalActions.push(item);
                                    }
                                });

                                const result = {
                                    key,
                                    controlState: {
                                        ...controlState,
                                        configUpdateByRelation,
                                        updatedAt
                                    }
                                };
                                const fieldConfigUpdates: any = {};

                                if (
                                    controlState.fieldConfig.relations?.length &&
                                    conditionalActions.length
                                ) {
                                    conditionalActions.forEach(cond => {
                                        const relation = this.relationsManager.fieldToConditionals(
                                            controlState.fieldConfig as FieldConfig,
                                            cond.action
                                        );

                                        if (relation.conditionResult) {
                                            configUpdateByRelation = true;
                                            updatedAt = Date.now();
                                            fieldConfigUpdates[cond.prop] = relation.newValue;

                                            result.controlState = {
                                                ...controlState,
                                                configUpdateByRelation,
                                                fieldConfig: {
                                                    ...controlState.fieldConfig,
                                                    ...fieldConfigUpdates
                                                },
                                                updatedAt
                                            };
                                        }
                                    });
                                }

                                return result;
                            })
                            .reduce((acc, cur) => {
                                acc[cur.key] = cur.controlState;
                                return acc;
                            }, {} as typeof state.controls)
                    };
                }
                case DynamicFormActionTypes.VALIDATE: {
                    return {
                        ...state,
                        controls: Lazy(action.refIds)
                            .map(key => state.controls[key])
                            // Validation is debounced, so it can happen that a control is queried for validation but beofre that happens it is removed.
                            .filter(
                                ctrl =>
                                    ctrl !== undefined &&
                                    (isControlConfig(ctrl.fieldConfig) ||
                                        ctrl.fieldConfig.type === 'option') &&
                                    ctrl.fieldConfig.type !== 'repeater'
                            )
                            .map(ctrl => {
                                const ctrlState = { ...ctrl } as DynamicFormControlState;

                                // reset validation
                                ctrlState.validation = null;

                                // ----------------------------------------------
                                //
                                //  `hidden` fields are always valid
                                //
                                // ----------------------------------------------

                                if (ctrlState.fieldConfig.hidden) {
                                    ctrlState.validation = null;

                                    // ----------------------------------------------
                                    //
                                    //  CheckboxGroup validation
                                    //
                                    // ----------------------------------------------
                                } else if (isCheckboxGroupFormControlState(ctrlState)) {
                                    const options = ctrlState.extra.options;

                                    const selectedOptions = options.filter(opt => {
                                        return (
                                            _get(state.data, getDataPathByFieldConfig(opt)) === true
                                        );
                                    });

                                    // ----------------------------------
                                    // At Least One Selected validation
                                    // ----------------------------------
                                    if (
                                        (ctrlState.fieldConfig.validation as ValidatorFn[]).find(
                                            val => val === Validators.required
                                        ) ||
                                        ctrlState.requiredByRelation
                                    ) {
                                        const atLeastOneSelected = selectedOptions.length > 0;
                                        ctrlState.validation = atLeastOneSelected
                                            ? null
                                            : { uncomplete: true };
                                    }

                                    // ------------------
                                    // Limit validation
                                    // ------------------
                                    const limit = ctrlState.fieldConfig.limit;
                                    if (limit !== undefined) {
                                        ctrlState.validation =
                                            selectedOptions.length <= limit
                                                ? ctrlState.validation
                                                : { ...(ctrlState.validation || {}), over: true };
                                    }

                                    // ----------------------------------------
                                    // Required checks validation
                                    // (the minimum checks needed to be valid)
                                    // ----------------------------------------
                                    const requiredChecksValidator =
                                        ctrlState.fieldConfig.validation.find(v =>
                                            isRequiredChecksValidator(v)
                                        );
                                    if (isRequiredChecksValidator(requiredChecksValidator)) {
                                        // do stuff...
                                        const validationResult = requiredChecksValidator.validate(
                                            ctrlState,
                                            selectedOptions
                                        );
                                        ctrlState.validation =
                                            validationResult === null
                                                ? ctrlState.validation
                                                : {
                                                      ...(ctrlState.validation || {}),
                                                      ...validationResult
                                                  };
                                    }

                                    // ----------------------------------------------
                                    //
                                    //  RadioGroup validation
                                    //
                                    // ----------------------------------------------
                                } else if (ctrlState.fieldConfig.type === 'radioGroup') {
                                    if (
                                        (ctrlState.fieldConfig.validation as ValidatorFn[]).find(
                                            val => val === Validators.required
                                        ) ||
                                        ctrlState.requiredByRelation
                                    ) {
                                        let radioGroupValue: string = _get(
                                            state.data,
                                            getDataPathByFieldConfig(ctrlState.fieldConfig)
                                        );
                                        radioGroupValue = (
                                            objectHasValue(radioGroupValue) ? radioGroupValue : ''
                                        ).toString();

                                        const options = (ctrlState as RadioGroupControlState).extra
                                            .options;

                                        const hasOtherValues = options.find(
                                            item => item.otherValues && item.otherValues.length > 0
                                        );

                                        // ------------------------------
                                        // Validation with otherValues
                                        // ------------------------------
                                        if (hasOtherValues) {
                                            // If has wildcard `*` there will always be a selection, therefore it's valid.
                                            const hasWildcard = options.find(
                                                opt =>
                                                    opt.otherValues &&
                                                    opt.otherValues.find(
                                                        otherValue => otherValue === '*'
                                                    )
                                            );
                                            if (hasWildcard) {
                                                ctrlState.validation = null;
                                            } else {
                                                const match = options.find(
                                                    opt =>
                                                        (objectHasValue(opt.value) &&
                                                            opt.value.toString() ===
                                                                radioGroupValue) ||
                                                        (opt.otherValues &&
                                                            opt.otherValues.find(
                                                                otherValue =>
                                                                    otherValue === radioGroupValue
                                                            ))
                                                );
                                                if (!match) {
                                                    ctrlState.validation = {
                                                        required: true
                                                    };
                                                }
                                            }
                                        } else {
                                            // --------------------------------
                                            // Validation without otherValues
                                            // --------------------------------
                                            const match = options.find(
                                                opt =>
                                                    objectHasValue(opt.value) &&
                                                    opt.value.toString() === radioGroupValue
                                            );
                                            if (!match) {
                                                ctrlState.validation = {
                                                    required: true
                                                };
                                            }
                                        }
                                    }

                                    // ----------------------------------------------
                                    //
                                    //  Dropdown validation
                                    //
                                    // ----------------------------------------------
                                } else if (ctrlState.fieldConfig.type === 'dropdown') {
                                    const dropdownState = ctrlState as DropdownControlState;
                                    const dropdownFieldConfig =
                                        dropdownState.fieldConfig as SelectFieldConfig;
                                    const options = dropdownState.extra.options;
                                    const dropdownValue = _get(
                                        state.data,
                                        getDataPathByFieldConfig(dropdownFieldConfig)
                                    );
                                    const selectedOption = options.find(option => {
                                        const optValue =
                                            option[dropdownFieldConfig.valueField as string];
                                        return dropdownValue === optValue;
                                    });

                                    const dropdownHasValue =
                                        (Array.isArray(dropdownValue) &&
                                            dropdownValue.length > 0) ||
                                        stringIsNotEmpty(dropdownValue);
                                    const validators = [
                                        ...(dropdownState.fieldConfig.validation as ValidatorFn[])
                                    ];
                                    if (dropdownState.requiredByRelation) {
                                        validators.push(Validators.required);
                                    }
                                    const isInvalid =
                                        selectedOption === undefined && dropdownHasValue;
                                    ctrlState.validation = isInvalid
                                        ? {
                                              dropdown: `"${dropdownValue}" is not a valid ${dropdownFieldConfig.label}`
                                          }
                                        : !dropdownHasValue &&
                                          validators.find(val => val === Validators.required)
                                        ? { required: true }
                                        : null;
                                } else if (ctrlState.fieldConfig.type === 'intlPhone') {
                                    const fieldConfig =
                                        ctrlState.fieldConfig as IntlPhoneFieldConfig;
                                    const dataPath = getDataPathByFieldConfig(fieldConfig);
                                    const dataPathCountryCode = fieldConfig.group
                                        ? `${fieldConfig.group}.${fieldConfig.countryCodeName}`
                                        : fieldConfig.countryCodeName;

                                    // We don't validate the country code input, we do all validations in the phone input
                                    if (dataPath !== dataPathCountryCode) {
                                        const countryCode = _get(state.data, dataPathCountryCode);
                                        const formControlCountryCode = new UntypedFormControl(); // for validation

                                        const validators = [];
                                        if (ctrlState.requiredByRelation) {
                                            validators.push(Validators.required);
                                        }

                                        formControl.setValue(_get(state.data, dataPath));
                                        formControlCountryCode.setValue(
                                            _get(state.data, dataPathCountryCode)
                                        );

                                        (ctrlState.fieldConfig.validation as ValidatorFn[]).forEach(
                                            validator => {
                                                validators.push(validator);
                                            }
                                        );

                                        const validationErrors = Lazy(validators)
                                            .map(fn =>
                                                formControl.value &&
                                                fn.name === 'intlPhoneValidator'
                                                    ? (fn as any)(formControl, countryCode)
                                                    : fn(formControl)
                                            )
                                            .filter(validationResult => validationResult !== null)
                                            .reduce(
                                                (acc, cur) => ({ ...acc, ...cur }),
                                                {}
                                            ) as ValidationErrors;
                                        ctrlState.validation =
                                            Object.keys(validationErrors).length > 0
                                                ? validationErrors
                                                : null;
                                    }
                                } else {
                                    // ----------------------------------------------
                                    //
                                    //  Other controls validation
                                    //
                                    // ----------------------------------------------

                                    const dataPath = getDataPathByFieldConfig(
                                        ctrlState.fieldConfig
                                    );
                                    formControl.setValue(_get(state.data, dataPath));
                                    const validators = [
                                        ...(ctrlState.fieldConfig.validation as ValidatorFn[])
                                    ];
                                    if (ctrlState.requiredByRelation) {
                                        const validator =
                                            ctrlState.fieldConfig.type === 'checkbox'
                                                ? Validators.requiredTrue
                                                : Validators.required;
                                        validators.push(validator);
                                    }
                                    const validationErrors = Lazy(validators)
                                        .map(fn => fn(formControl))
                                        .filter(validationResult => validationResult !== null)
                                        .reduce(
                                            (acc, cur) => ({ ...acc, ...cur }),
                                            {}
                                        ) as ValidationErrors;
                                    ctrlState.validation =
                                        Object.keys(validationErrors).length > 0
                                            ? validationErrors
                                            : null;
                                }

                                // Mark control for change detection
                                ctrlState.updatedAt = Date.now();

                                return ctrlState;
                            })
                            .reduce(
                                (controls, ctrl) => {
                                    controls[ctrl.fieldConfig.refId as string] = ctrl;
                                    return controls;
                                },
                                { ...state.controls }
                            )
                    };
                }
                case DynamicFormActionTypes.INJECT_SERVER_VALIDATOR: {
                    const validationError = action.error;
                    const path = validationError.path;

                    const ctrls: DynamicFormControlState[] = getControlsByDataPath(
                        state.controls,
                        path
                    ).map(ctrl => ({
                        ...ctrl,
                        injectedServerValidationErrors: [
                            ...ctrl.injectedServerValidationErrors,
                            validationError.message
                        ]
                    }));
                    ctrls.forEach(ctrl => {
                        this.validateEmitter$.next(ctrl.fieldConfig.refId as string);
                    });

                    return {
                        ...state,
                        controls: {
                            ...state.controls,
                            ...ctrls.reduce((acc, cur) => {
                                acc[cur.fieldConfig.refId as string] = cur;
                                return acc;
                            }, {} as typeof state.controls)
                        }
                    };
                }
                case DynamicFormActionTypes.CLEAR_SERVER_VALIDATORS: {
                    const ctrls = getControlsByDataPath(state.controls, action.path).map(ctrl => ({
                        ...ctrl,
                        injectedServerValidationErrors: []
                    }));
                    ctrls.forEach(ctrl => {
                        this.validateEmitter$.next(ctrl.fieldConfig.refId as string);
                    });
                    return {
                        ...state,
                        controls: {
                            ...state.controls,
                            ...ctrls.reduce((acc, cur) => {
                                acc[cur.fieldConfig.refId as string] = cur;
                                return acc;
                            }, {} as typeof state.controls)
                        }
                    };
                }
                case DynamicFormActionTypes.RECALCULATE_CALC_EXPRESSIONS: {
                    // Sort controls by its dependency on other calcExp (Topological order)
                    /**
                     * First we want to create the refId dependency graph.
                     * const deps = {
                     *   calc1: ["num1", "num2"],
                     *   num1: [],
                     *   num2: []
                     *   calc2: ["calc1"]
                     * };
                     */
                    const deps = Lazy(Object.values(state.controls))
                        // we're only concerned about the ones that have calcExps
                        .filter(
                            ctrl =>
                                (ctrl.fieldConfig as CalculatedFieldConfig).calculated !== undefined
                        )
                        .reduce((acc, ctrl) => {
                            acc[ctrl.fieldConfig.refId as string] =
                                this.calcExpManager.getExpressionRefIds(
                                    (ctrl.fieldConfig as CalculatedFieldConfig)
                                        .calculated as CalculatedFieldOptionsConfig
                                );
                            return acc;
                        }, {} as Record<string, string[]>);
                    /**
                     * Then we create the edges in this format:
                     * [
                     *  ["calc1", "num1"],
                     *  ["calc1", "num2"]
                     *  ["calc2", "calc1"]
                     * ]
                     */

                    const graph = Lazy(Object.keys(deps))
                        .filter(key => deps[key].length > 0)
                        .map(key => deps[key].map(dep => [key, dep]))
                        .reduce(
                            (acc, cur) =>
                                cur.reduce((acc2, cur2) => {
                                    acc2.push(cur2);
                                    return acc2;
                                }, acc),
                            [] as string[][]
                        ) as [string, string][];
                    const sortedRefIds: string[] = toposort(graph).reverse();

                    const changedRefIds: RefId[] = [];
                    Lazy(sortedRefIds)
                        .map(refId => state.controls[refId])
                        // skip controls hidden by relations
                        .filter(controlState => controlState !== undefined)
                        .each(controlState => {
                            const fieldConfig = controlState.fieldConfig as LimitedDecimalConfig;
                            if (fieldConfig.calculated) {
                                this.validateEmitter$.next(fieldConfig.refId as string);
                                // We need to mutate so the next calcExp that depends on another calcExp has the latest calculated value. Otherwise we'd always be one step behind!
                                if (
                                    fieldConfig.type === 'textInput' ||
                                    fieldConfig.type === 'textarea'
                                ) {
                                    _set(
                                        state.data,
                                        getDataPathByFieldConfig(fieldConfig),
                                        this.calcExpManager.getParsedResult(fieldConfig.calculated)
                                    );
                                } else {
                                    _set(
                                        state.data,
                                        getDataPathByFieldConfig(fieldConfig),
                                        calculateDecimalLimitedValue(
                                            this.calcExpManager.getParsedResult(
                                                fieldConfig.calculated
                                            ),
                                            fieldConfig.decimalLimit,
                                            fieldConfig.allowDecimal,
                                            fieldConfig.allowNegative
                                        )
                                    );
                                }

                                changedRefIds.push(fieldConfig.refId as string);
                            }
                        });

                    return {
                        ...state,
                        data: {
                            ...state.data
                        },
                        controls: {
                            ...state.controls,
                            ...changedRefIds.reduce((acc, refId) => {
                                acc[refId] = {
                                    ...state.controls[refId],
                                    updatedAt: Date.now()
                                };
                                return acc;
                            }, {} as typeof state.controls)
                        }
                    };
                }
                case DynamicFormActionTypes.ASSIGN_PATCHED_VALUE: {
                    this.recalculateCalcExpressionsEmitter$.next(null as never);
                    this.recalculateDisableRelationsEmitter$.next(null as never);
                    this.recalculateRequireRelationsEmitter$.next(null as never);
                    this.recalculateSetLabelConditionalsEmitter$.next(null as never);

                    return {
                        ...state,
                        data: Lazy(Object.entries(state.controls))
                            .filter(([_, controlState]) => {
                                /** Raul - We have to filter checkboxGroups because first the checkbox set the value and
                                 * clears `this.patchedValue`, then on the next iteration, the checkboxGroup has a
                                 * `this.patchedValue` empty and patches again.
                                 * We have to call `validateEmitter$` to refresh the UI otherwise the value is applied
                                 * but the checkboxes are not rendered as checked.
                                 */
                                if (controlState.fieldConfig.type === 'checkboxGroup') {
                                    this.validateEmitter$.next(
                                        controlState.fieldConfig.refId as string
                                    );
                                }

                                return (
                                    controlState.fieldConfig.type !== 'checkboxGroup' &&
                                    // Non controls don't need data processing
                                    controlState.fieldConfig.type !== 'freeText' &&
                                    controlState.fieldConfig.type !== 'expandableText' &&
                                    controlState.fieldConfig.type !== 'group' &&
                                    controlState.fieldConfig.type !== 'groupLight'
                                );
                            })
                            .map(([_, controlState]) => {
                                const path = getDataPathByFieldConfig(controlState.fieldConfig);
                                const patchedValue = _get(this.patchedValue, path);
                                // The patched value is a one-off thing, that's why we get rid of it once it has been set.
                                _unset(this.patchedValue, path);
                                const currentValue = _get(state.data, path);

                                if (currentValue !== patchedValue) {
                                    // there might be more than one control assigned to this path
                                    const controls = getControlsByDataPath(state.controls, path);
                                    controls.forEach(ctrl => {
                                        this.validateEmitter$.next(
                                            ctrl.fieldConfig.refId as string
                                        );
                                        // Mark control for change detection
                                        _set(state.controls, ctrl.fieldConfig.refId as string, {
                                            ...ctrl,
                                            // Mark control for change detection
                                            updatedAt: Date.now()
                                        });
                                    });
                                }

                                const isRepeater = controlState.fieldConfig.type === 'repeater';
                                if (isRepeater && patchedValue !== undefined) {
                                    // set compiledTemplate by mutation
                                    state.controls[controlState.fieldConfig.refId as string] = {
                                        ...state.controls[controlState.fieldConfig.refId as string],
                                        extra: {
                                            compiledTemplate: compileRepeaterTemplate(
                                                controlState.fieldConfig as RepeaterFieldConfig,
                                                patchedValue,
                                                false
                                            )
                                        }
                                    } as RepeaterControlState;

                                    // calculate new data
                                    const currentRepeaterData = _clone(currentValue || []);
                                    const newRepeaterData = _merge(
                                        currentRepeaterData,
                                        patchedValue
                                    );

                                    return {
                                        path,
                                        data: newRepeaterData
                                    };
                                } else {
                                    return {
                                        path,
                                        data:
                                            patchedValue !== undefined ? patchedValue : currentValue
                                    };
                                }
                            })
                            .reduce(
                                (acc, cur) => {
                                    _set(acc, cur.path, cur.data);
                                    return acc;
                                },
                                { ...state.data }
                            ),
                        controls: { ...state.controls }
                    };
                }
                case DynamicFormActionTypes.SET_CONTROL_PROPERTY: {
                    _set(state.controls[action.refId], action.path, action.value);
                    return {
                        ...state,
                        controls: {
                            ...state.controls,
                            // Mark control for change detection
                            [action.refId]: {
                                ...state.controls[action.refId],
                                updatedAt: Date.now()
                            }
                        }
                    };
                }
                default: {
                    return state;
                }
            }
        };

        return (
            state: DynamicFormState = initialState,
            action: DynamicFormActions
        ): DynamicFormState => {
            if (action.formUID) {
                switch (action.formUID.value) {
                    case formUID.value:
                        return reducer(state, action);
                    default:
                        return state;
                }
            } else {
                return state;
            }
        };
    }
}
