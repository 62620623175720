<div class="header--section u-mt40">
    <div class="-heading">
        <h3>Account Information</h3>
    </div>
</div>

<h5>Account Details</h5>
<p class="u-mt8">Select an investor account.</p>

<form [formGroup]="form">
    <div class="aix-form__container">
        <div class="aix-form__group u-mt16">
            <aix-dropdown
                #accountDropdownRef
                [aixPendingField]="{ control: form.get('accountSelected') }"
                [formControlName]="'accountSelected'"
                [isRequired]="true"
                [isValid]="!accountDropdownRef.isActionButtonSelected && !form.get('accountSelected')?.value ? !!form.get('accountSelected')?.valid : false"
                labelField="displayName"
                valueField="id"
                placeholder=""
                [data-testing]="'account'"
                [options]="accounts()"
                (filter)="onFilterAccounts($event)"
                [omitFiltering]="true"
                [actionButtonLabel]="'Add Investor Account'"
                (onClickAction)="onClickDropdownAction()"
                (onBlurred)="onBlurInvestorAccount()"
            >
                Investor Account
            </aix-dropdown>
        </div>
    </div>
</form>
