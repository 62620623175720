<div [formGroup]="form">
    <h5>{{ this.labelType() === 'recordkeeper' ? 'Recordkeeper' : 'Custodial Arrangement' }}</h5>
    <p class="u-mt8">
        Select where the investment {{ this.labelType() === 'maintenance' ? 'is currently' : 'will
        be' }} held.
    </p>

    <div class="aix-flex-grid__col u-mt16">
        <div class="aix-form__group" [data-testing]="'holding-option'">
            <aix-dropdown
                #holdingOptionRef
                [aixPendingField]="{ control: form.get('holdingOption') }"
                [data-testing]="'holding-option-dropdown'"
                [formControlName]="'holdingOption'"
                [isRequired]="true"
                [isValid]="!!holdingOption"
                [options]="holdingOptions()"
                labelField="name"
                valueField="id"
                placeholder=""
                [itemMessage]="itemMessage"
                (ngModelChange)="holdingOption = $event && $event.length > 0 ? $event[0].id : null"
                [aixDisabled]="form.get('holdingOption')?.disabled"
            >
                <span [data-testing]="'title'"
                    >{{ this.labelType() === 'recordkeeper' ? 'Recordkeeper' : 'Holding Option'
                    }}</span
                >
            </aix-dropdown>
        </div>
    </div>
</div>
